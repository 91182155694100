 <template>
  <div
    class="fill-height"
    style="padding-bottom: 100px;">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-img
            width="100%"
            min-height="100vh"
            alt="Pemerintah Kabupaten Klaten"
            class="py-16"
            :src="require('@/assets/background/bg_basic.png')">
            <v-container>
              <v-row>
                <v-col
                  cols="12">
                  <v-card
                    flat
                    color="transparent">
                    <v-card-title
                      class="text-h4 white--text justify-center mb-2"
                      style="font-weight: 500;">
                      Visi & Misi
                    </v-card-title>

                    <v-card-subtitle
                      class="body-1 white--text text-center mb-6"
                      style="opacity: 50%; font-weight: 500;">
                      Tujuan aplikasi potensi di Klaten.
                    </v-card-subtitle>

                    <v-card-text
                      class="body-1 white--text line-text-second mb-4"
                      style="opacity: 50%;">
                      <div
                        class="mb-6"
                        style="text-indent: 50px;">
                        What is Lorem Ipsum?

                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                      </div>

                      <div
                        class="mb-6"
                        style="text-indent: 50px;">
                        What is Lorem Ipsum?

                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                      </div>

                      <div
                        class="mb-6">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                      </div>

                      <div
                        class="mb-6"
                        style="text-indent: 50px;">
                        What is Lorem Ipsum?

                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                      </div>

                      <div
                        class="mb-6">
                        It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                      </div>

                      <div
                        class="mb-6"
                        style="text-indent: 50px;">
                        What is Lorem Ipsum?

                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-img>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        process: {
          load: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/profile/visi-misi',
          title: 'Visi & Misi',
          description: 'Visi & Misi Kabupaten Klaten',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {

    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      },
      set_color_rgb_down () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.25)`
      }
    },
    mounted () {

    },
    methods: {

    }
  }
</script>
