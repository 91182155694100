<template>
  <div>
    <!-- <v-app-bar
      app
      fixed
      class="px-2"
      :class="$route.name === '/' || $route.name === 'beranda' ? 'header' : ''"
      elevation="0"
      :prominent="false"
      :hide-on-scroll="false"
      scroll-target="#scrolling-techniques-4"
      :style="`background-color: ${set_color}`"
      style="z-index: 3; box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;">
      <v-container>
        <v-row>
          <v-col
            class="d-flex align-center">
            <div>
              <nuxt-link
                to="/beranda"
                class="d-flex align-center"
                style="opacity: 60%;">
                <v-img
                  contain
                  width="120"
                  height="auto"
                  :alt="company.unit_title"
                  :src="require('@/assets/logo/favicon.png')"
                  class="ma-auto">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </nuxt-link>
            </div>

            <v-spacer />

              <div
                  v-for="(item, index) in menu"
                  :key="index">

                  <div v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' && item.sub === undefined">
                    <v-btn
                      :to="item.link"
                      text
                      class="body-2 text-capitalize px-3 ml-2"
                      :class="$route.path.split('/')[1] === item.link.split('/')[1] ? 'font-weight-bold' : ''"
                      :style="$route.path.split('/')[1] === item.link.split('/')[1] ? 'color: #FFFFFF;' : 'color: #FFFFFF;'"
                      style="border-radius: 50px;">
                      {{ item.title }}
                    </v-btn>
                  </div>

                  <div v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' && item.sub !== undefined">
                    <v-menu offset-y open-on-hover>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          text
                          v-bind="attrs"
                          v-on="on"
                          class="body-2 text-capitalize px-3 ml-2"
                          :class="$route.path.split('/')[1] === item.link.split('/')[1] ? 'font-weight-bold' : ''"
                          :style="$route.path.split('/')[1] === item.link.split('/')[1] ? 'color: #FFFFFF;' : 'color: #FFFFFF;'"
                          style="border-radius: 50px;">
                          {{ item.title }}
                          <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-card min-width="280">
                        <v-list>
                          <v-list-item
                            v-for="(i, m) in item.sub"
                            :key="m"
                          >
                            <v-list-item-title>
                              <v-hover v-slot="{ hover }">
                                <div :class="hover ? 'font-weight-bold' : ''" class="cursor-pointer" :style="hover ? `color: ${set_color}` : ''">
                                  <div
                                    style="color: rgba(0, 0, 0, 0.87)"
                                    @click="toList(i.link)"
                                    :class="hover ? 'font-weight-bold' : $route.fullPath === i.link ? 'font-weight-bold' : ''" class="cursor-pointer" :style="hover ? `color: ${set_color}` : $route.fullPath === i.link ? `color: ${set_color}` : ''" >
                                    {{ i.title }}
                                  </div>
                                </div>
                              </v-hover>
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-card>
                    </v-menu>
                  </div>
              </div>

          </v-col>
        </v-row>
      </v-container>
    </v-app-bar> -->

    <v-app-bar
      app
      fixed
      class="px-2"
      :class="$route.name === '/' || $route.name === 'beranda' ? 'header' : ''"
      :prominent="false"
      :hide-on-scroll="false"
      scroll-target="#scrolling-techniques-4"
      :style="`background-color: #fff`"
      style="height: 128px; z-index: 3; box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;">
      <v-container class="px-0 py-0">
        <v-row>
          <v-col
            class="d-flex align-center pb-0">
            <div>
              <nuxt-link
                to="/"
                class="d-flex align-center">
                <v-img
                  contain
                  width="120"
                  height="auto"
                  :alt="company.unit_title"
                  :src="company.logo_url === '' ? require('@/assets/logo/favicon.png') : company.logo_url"
                  class="ma-auto">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </nuxt-link>
            </div>

            <v-spacer />

            <!-- <div v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'">

            </div> -->

            <!-- <div v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'"> -->
              <div
                  v-for="(item, index) in menu"
                  :key="index">

                  <div v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' && item.sub === undefined">
                    <v-btn
                      :to="item.link"
                      text
                      @mouseover="voiceHover(item.title)"
                      class="body-2 text-capitalize px-2"
                      :class="$route.name === 'berita' ? $route.name === item.link ? 'font-weight-bold' : '' : $route.path.split('/')[1] === item.link.split('/')[1] ? 'font-weight-bold' : ''"
                      :style="$route.name === 'berita' ? $route.name === item.link ? `color: ${set_color}` : '' : $route.path.split('/')[1] === item.link.split('/')[1] ? `color: ${set_color}` : ''"
                      style="border-radius: 50px;">
                      {{ item.title }}
                    </v-btn>
                  </div>

                  <div v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' && item.sub !== undefined">
                    <v-menu offset-y open-on-hover>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          text
                          v-bind="attrs"
                          v-on="on"
                          @mouseover="voiceHover(item.title)"
                          class="body-2 text-capitalize px-2"
                          :class="$route.name === item.link ? 'font-weight-bold' : ''"
                          :style="$route.name === item.link ? `color: ${set_color}` : ``"
                          style="border-radius: 50px;">
                          {{ item.title }}
                          <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-card min-width="280">
                        <v-list v-if="item.title !== 'Informasi' && item.title !== 'PPID'">
                          <v-list-item
                            v-for="(i, m) in item.sub"
                            :key="m"
                          >
                            <v-list-item-title>
                              <v-hover v-slot="{ hover }">
                                <div :class="hover ? 'font-weight-bold' : ''" class="cursor-pointer" :style="hover ? `color: ${set_color}` : ''">
                                  <!-- {{ i.title }} -->
                                  <nuxt-link :to="i.link">
                                    <div
                                      style="color: rgba(0, 0, 0, 0.87)"
                                      v-if="item.link === 'profil'"
                                      @mouseover="voiceHover(i.title)"
                                      :class="hover ? 'font-weight-bold' : $route.fullPath === i.link ? 'font-weight-bold' : ''" class="cursor-pointer" :style="hover ? `color: ${set_color}` : $route.fullPath === i.link ? `color: ${set_color}` : ''" >
                                      {{ i.title }}
                                    </div>
                                  </nuxt-link>


                                  <div
                                    style="color: rgba(0, 0, 0, 0.87)"
                                    v-if="item.link !== 'profil'"
                                    @mouseover="voiceHover(i.title)"
                                    @click="filterSubMenu(i.link, item.link)"
                                    :class="hover ? 'font-weight-bold' : $route.query.category === undefined && $route.name === item.link ? i.link === '' ? 'font-weight-bold' : '' : $route.name === item.link && $route.query.category === i.link ? 'font-weight-bold' : ''" class="cursor-pointer" :style="hover ? `color: ${set_color}` : $route.query.category === undefined && $route.name === item.link ? i.link === '' ? `color: ${set_color}` : '' : $route.name === item.link && $route.query.category === i.link ? `color: ${set_color}` : ''" >
                                    {{ i.title }}
                                  </div>
                                </div>
                              </v-hover>
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>

                        <v-list v-if="item.title === 'Informasi'">
                          <v-list-item
                            v-for="(i, m) in item.sub"
                            :key="m"
                          >
                            <v-list-item-title>
                              <v-hover v-slot="{ hover }">
                                <div :class="hover ? 'font-weight-bold' : ''" class="cursor-pointer" :style="hover ? `color: ${set_color}` : ''">
                                  <div
                                    style="color: rgba(0, 0, 0, 0.87)"
                                    @click="toList(i.link)"
                                    @mouseover="voiceHover(i.title)"
                                    :class="hover ? 'font-weight-bold' : $route.fullPath === i.link ? 'font-weight-bold' : ''" class="cursor-pointer" :style="hover ? `color: ${set_color}` : $route.fullPath === i.link ? `color: ${set_color}` : ''" >
                                    {{ i.title }}
                                  </div>
                                </div>
                              </v-hover>
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>


                        <v-list v-if="item.title === 'PPID'">
                          <v-list-item
                            v-for="(i, m) in item.sub"
                            :key="m"
                          >
                            <v-list-item-title>
                              <v-hover v-slot="{ hover }">
                                <div :class="hover ? 'font-weight-bold' : ''" class="cursor-pointer" :style="hover ? `color: ${set_color}` : ''">
                                  <v-card
                                    style="color: rgba(0, 0, 0, 0.87)"
                                    flat
                                    target="_blank"
                                    :href="i.link"
                                    @mouseover="voiceHover(i.title)"
                                    :class="hover ? 'font-weight-bold' : $route.fullPath === i.link ? 'font-weight-bold' : ''" class="cursor-pointer pa-0" :style="hover ? `color: ${set_color}` : $route.fullPath === i.link ? `color: ${set_color}` : ''" >
                                    {{ i.title }}
                                  </v-card>
                                </div>
                              </v-hover>
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-card>
                    </v-menu>
                  </div>
              </div>
            <!-- </div> -->
            <div
              v-for="(item, index) in menu_dinamis"
              :key="index">
              <div v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' && item.children === undefined">
                <v-btn
                  :to="`/page/${item.url}`"
                  text
                  @mouseover="voiceHover(item.title)"
                  class="body-2 text-capitalize px-2"
                  :class="$route.path=== item.link? 'font-weight-bold' : ''"
                  :style="$route.path=== item.link? `color: ${set_color}` : ''"
                  style="border-radius: 50px;">
                  {{ item.title }}
                </v-btn>
              </div>

              <div v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' && item.children !== undefined">
                <v-menu offset-y open-on-hover>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          text
                          v-bind="attrs"
                          v-on="on"
                          @mouseover="voiceHover(item.title)"
                          class="body-2 text-capitalize px-2"
                          :class="$route.name === item.link ? 'font-weight-bold' : ''"
                          :style="$route.name === item.link ? `color: ${set_color}` : ``"
                          style="border-radius: 50px;">
                          {{ item.title }}
                          <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-card min-width="280">
                        <v-list>
                          <v-list-item
                            v-for="(i, m) in item.children"
                            :key="m"
                          >
                            <v-list-item-title>
                              <v-hover v-slot="{ hover }">
                                <div :class="hover ? 'font-weight-bold' : ''" class="cursor-pointer" :style="hover ? `color: ${set_color}` : ''">
                                  <!-- {{ i.title }} -->
                                  <nuxt-link :to="`/page/${i.url}`">
                                    <div
                                      style="color: rgba(0, 0, 0, 0.87)"
                                      @mouseover="voiceHover(i.title)"
                                      :class="hover ? 'font-weight-bold' : $route.fullPath === i.link ? 'font-weight-bold' : ''" class="cursor-pointer" :style="hover ? `color: ${set_color}` : $route.fullPath === i.link ? `color: ${set_color}` : ''" >
                                      {{ i.title }}
                                    </div>
                                  </nuxt-link>
                                </div>
                              </v-hover>
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-card>
                    </v-menu>
              </div>
            </div>

          </v-col>
        </v-row>

        <v-row>
          <v-col :cols="3">
            <v-select
              :items="category"
              :color="`${set_color}`"
              v-model="selected.category"
              :item-color="`${set_color}`"
              hide-details
              item-text="name"
              item-value="link"
              v-on:change="toFilterCategory()"
              placeholder="Kategori"
              solo
            ></v-select>
          </v-col>
          <v-col cols="9">
            <div class="d-flex">
              <v-text-field
                solo
                hide-details
                clearable
                v-model="search"
                v-on:keyup.enter="changeQuery()"
                @click:clear="search = ''; changeQuery();"
                style="border-radius: 6px 0px 0px 6px !important;"
                :color="`${set_color}`"
                :placeholder="`Cari ${selected.category} yang Anda butuhkan . . .`">
                <template v-slot:prepend-inner>
                  <v-icon class="mr-2">mdi-magnify</v-icon>
                </template>
              </v-text-field>
              <v-btn
                  :color="`${set_color}`"
                  height="48"
                  elevation="0"
                  dark
                  @click="changeQuery()"
                  class="text-capitalize"
                  style="border-radius: 0px 6px 6px 0px;"
                >
                <v-icon size="20" class="mr-2">mdi-magnify</v-icon>
                      Cari
                </v-btn>
            </div>
            <!-- <v-text-field
              dense
              hide-details
              clearable
              outlined
              :placeholder="`Cari kelas elearning semua kategori`">
              <template v-slot:prepend-inner>
                <v-icon class="mr-2">mdi-magnify</v-icon>
              </template>
            </v-text-field> -->
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  export default {
    middleware: 'notAuthenticated',
    data () {
      return {
        clipped: false,
        drawer: true,
        dialog: {
          auth: false,
          otp: false,
          info: false
        },
        category: [
          {
            name: 'Berita',
            link: 'berita'
          },,
          {
            name: 'Pengumuman',
            link: 'pengumuman'
          },
          {
            name: 'Potensi',
            link: 'potensi'
          },
          {
            name: 'Layanan',
            link: 'layanan'
          }
        ],
        selected: {
          auth: 'login',
          category: 'berita'
        },
        menu: [
          {
            title: 'Beranda',
            link: '/'
          },
          {
            title: 'Profil',
            link: 'profil',
            sub: [
              // {
              //   title: 'Profil Pejabat',
              //   link: '/profil?menu=pejabat'
              // },
              {
                title: 'Visi dan Misi',
                link: '/profil?menu=visi-misi'
              },
              {
                title: 'Sejarah Singkat',
                link: '/profil?menu=sejarah'
              },
              {
                title: 'Lambang',
                link: '/profil?menu=lambang'
              },
              {
                title: 'Struktur dan Organisasi',
                link: '/profil?menu=struktur'
              },
              {
                title: 'Daftar Pejabat',
                link: '/profil?menu=daftar-pejabat'
              },
              {
                title: 'Geografi dan Topologi',
                link: '/profil?menu=geografis'
              },
              {
                title: 'Tugas dan Fungsi Pemerintah',
                link: '/profil?menu=tugas-fungsi'
              },
              {
                title: 'Daftar Website',
                link: '/profil?menu=daftar-website'
              },
            ]
          },
          // {
          //   title: 'PPID',
          //   link: '/PPID'
          // },
          {
            title: 'PPID',
            link: 'PPID',
            sub: [
              {
                title: 'Profil PPID',
                link: 'https://ppid.klaten.go.id/welcome/tugas_kewenangan'
              },
              {
                title: 'Informasi Publik Berkala',
                link: 'https://ppid.klaten.go.id/welcome/berkala'
              },
              {
                title: 'Informasi Publik Setiap Saat',
                link: 'https://ppid.klaten.go.id/welcome/setiap_saat'
              },
              {
                title: 'Informasi Publik Serta Merta',
                link: 'https://ppid.klaten.go.id/welcome/serta_merta'
              },
              {
                title: 'Permintaan Informasi',
                link: 'https://ppid.klaten.go.id/welcome/alur'
              },
              {
                title: 'Pengajuan Keberatan',
                link: 'https://ppid.klaten.go.id/welcome/dokumen'
              },
              {
                title: 'Daftar Informasi Dikecualikan',
                link: 'https://ppid.klaten.go.id/welcome/dokumen'
              },
              {
                title: 'Daftar Informasi Publik',
                link: 'https://ppid.klaten.go.id/welcome/dokumen'
              },
            ]
          },
          {
            title: 'Layanan',
            link: 'layanan',
            sub: [
              {
                title: "Semua Kategori",
                link: "semua-kategori"
              }
            ]
          },
          {
            title: 'Informasi',
            link: '/informasi',
            sub: [
              {
                title: 'Berita',
                link: '/berita'
              },
              {
                title: 'Pengumuman',
                link: '/pengumuman'
              },
              {
                title: 'Potensi',
                link: '/potensi'
              },
              {
                title: 'Video',
                link: '/video'
              },
              {
                title: 'Galeri',
                link: '/galeri'
              },
            ]
          },
          // {
          //   title: 'Potensi',
          //   link: 'potensi',
          //   sub: [
          //     {
          //       title: "Semua Kategori",
          //       link: "semua-kategori"
          //     }
          //   ]
          // },
          {
            title: 'Agenda',
            link: '/agenda'
          },
        ],
        menu_dinamis: [],
      }
    },
    components: {

    },
    watch: {
      '$route': function() {
        if (this.$route.path.split('/')[1] === 'berita') {
          this.selected.category = this.$route.path.split('/')[1]
        } else if (this.$route.path.split('/')[1] === 'potensi') {
          this.selected.category = this.$route.path.split('/')[1]
        } else if (this.$route.path.split('/')[1] === 'layanan') {
          this.selected.category = this.$route.path.split('/')[1]
        } else if (this.$route.path.split('/')[1] === 'pengumuman') {
          this.selected.category = this.$route.path.split('/')[1]
        }else {
          this.search = ''
          this.selected.category = 'berita'
        }
      },
      // '$route.query.category': function(val) {
      //   for(let i = 0; i < this.category.length; i++){
      //     if (this.category[i].slug === val) {
      //       this.selected.category = this.category[i].slug
      //     } else {
      //       // status = false
      //     }
      //   }
      // },
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      token () {
        return this.$store.state.member.auth.token
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      },
      unit () {
        return this.$store.state.member.unit.unit
      },
      company () {
        return this.$store.state.header.config.company
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      },
      set_color_rgb_down () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.25)`
      },

      // company () {
      //   return this.$store.state.header.config
      // },
      role_admin () {
        if (this.$route.name === null) {
          this.$router.push('/404')
        } else {
          return this.$route.name.includes('admin')
        }
      }
    },
    mounted () {
      const debounce = (fn) => {
        let frame;
        return (...params) => {
          if (frame) {
            cancelAnimationFrame(frame)
          }
          frame = requestAnimationFrame(() => {
            fn(...params)
          })
        }
      }

      const storeScroll = () => {
        document.documentElement.dataset.scroll = window.scrollY
      }

      document.addEventListener('scroll', debounce(storeScroll), { passive: true })

      storeScroll()
      if(this.$route.query.search !== undefined){
        this.search = this.$route.query.search
      } else {
        this.search = ''
      }
      this.fetchCategoryLayanan()
      this.fetchMenuDinamis()
    },
    beforeMount() {
      if (this.$route.path.split('/')[1] === 'berita') {
        this.selected.category = this.$route.path.split('/')[1]
      } else if (this.$route.path.split('/')[1] === 'potensi') {
        this.selected.category = this.$route.path.split('/')[1]
      } else if (this.$route.path.split('/')[1] === 'layanan') {
        this.selected.category = this.$route.path.split('/')[1]
      } else if (this.$route.path.split('/')[1] === 'pengumuman') {
        this.selected.category = this.$route.path.split('/')[1]
      }else {
        this.search = ''
        this.selected.category = 'berita'
      }
    },
    created() {

    },
    methods: {
      async fetchMenuDinamis () {
        // this.process.load = true

        let params = {
          limit: 1000000,
          unit_id: this.unit.id
        }

        await this.$axios.$get(`${process.env.API}sites/menu_page/data`, { params })
        .then(response => {
          let res = response

          if (res.status === 200) {
            this.menu_dinamis = this.changeData(res.results.data)
          }
        })
      },
      changeData (data_array) {
        function filter (array, condition) {
          let result = []

          for (let i = 0; i < array.length; i++) {
            if (condition(array[i])) {
              result.push(array[i])
            }
          }

          return result
        }

        function getChilds (parentKey, items) {
          let subItems = filter(items, function (n) {
            return n.parent_id === parentKey
          })

          let result = []

          for (let i = 0; i < subItems.length; i++) {
            let subItem = subItems[i]

            let kids = getChilds(subItem.id, items)
            if (kids.length) {
              subItem.children = kids
            }

            result.push(subItem)
          }

          if (parentKey.length) {
            return result
          } else {
            return result[0]
          }
        }

        const data = data_array

        let richMediaData = getChilds('0', data)

        return richMediaData
      },
      voiceHover (item) {
        responsiveVoice.cancel(); // Cancel anything else that may currently be speaking
  responsiveVoice.speak(item, 'Indonesian Female');
      },
      async fetchCategory () {
        // this.process.load = true

        let params = {
          limit: 1000000,
          unit_id: this.unit.id
        }

        await this.$axios.$get(`${process.env.API}sites/tour/tour_category`, { params })
        .then(response => {
          let res = response

          if (res.status === 200) {
            // this.menu[5].sub = [
            //   {
            //     title: "Semua Kategori",
            //     link: "semua-kategori"
            //   }
            // ]
            // let category = []
            // category = res.results.data.map(item => {
            //     const container = {};

            //     container.title = item.category_title;
            //     container.link = `${(item.category_title.split(' ').join('-')).toLowerCase()}`;

            //     // this.category = container;
            //     return container
            //   })

            //   console.log(category)
            // for (let i in category) {
            //   this.menu[5].sub.push(
            //     {
            //       title: category[i].title,
            //       link: category[i].link
            //     }
            //   )

            // }
            this.fetchCategoryLayanan()
              // console.log(usersByLikes)
            // this.pagination = res.results.pagination
            // this.fetch(res.results.data[0].id)
          }
        })
      },
      async fetchCategoryLayanan () {
        // this.process.load = true

        let params = {
          limit: 1000000,
          unit_id: this.unit.id
        }

        await this.$axios.$get(`${process.env.API}sites/services/services_category`, { params })
        .then(response => {
          let res = response

          if (res.status === 200) {
            this.menu[3].sub = [
              {
                title: "Semua Kategori",
                link: "semua-kategori"
              }
            ]
            let category = []
            category = res.results.data.map(item => {
                const container = {};

                container.title = item.category_title;
                container.link = `${(item.category_title.split(' ').join('-')).toLowerCase()}`;

                // this.category = container;
                return container
              })

              console.log(category)
            for (let i in category) {
              this.menu[3].sub.push(
                {
                  title: category[i].title,
                  link: category[i].link
                }
              )

            }
            this.fetchCategoryNews()
              // console.log(usersByLikes)
            // this.pagination = res.results.pagination
            // this.fetch(res.results.data[0].id)
          }
        })
      },
      async fetchCategoryNews () {
        // this.process.load = true

        let params = {
          limit: 1000000,
          par_id: '1',
          unit_id: this.unit.id
        }

        await this.$axios.$get(`${process.env.API}sites/article/category`, { params })
        .then(response => {
          let res = response

          if (res.status === 200) {
            // this.menu[4].sub = [
            //   {
            //     title: "Semua Kategori",
            //     link: "semua-kategori"
            //   }
            // ]
            // let category = []
            // category = res.results.data.map(item => {
            //     const container = {};

            //     container.title = item.title;
            //     container.link = item.slug;

            //     // this.category = container;
            //     return container
            //   })

            //   console.log(category)
            // for (let i in category) {
            //   this.menu[4].sub.push(
            //     {
            //       title: category[i].title,
            //       link: category[i].link
            //     }
            //   )

            // }
              // console.log(usersByLikes)
            // this.pagination = res.results.pagination
            // this.fetch(res.results.data[0].id)
          }
        })
      },
      changeQuery() {
        let category = this.$route.query.category
        if(this.$route.query.search === undefined) {
          if (this.$route.query.category === undefined) {
            this.$router.replace({'path': `/${this.selected.category}`, 'query': {'category': 'semua-kategori', 'search': this.search}})
          } else {
            this.$router.replace({'path': `/${this.selected.category}`, 'query': {'category': category, 'search': this.search}})
          }
        } else if (this.$route.query.search !== undefined && this.search !== '') {
          // console.log('cobaaa')
          // router.push({ path: 'page', query: { search: null } })
          if (this.$route.query.category === undefined) {
            this.$router.replace({'path': `/${this.selected.category}`, 'query': {'category': 'semua-kategori', 'search': this.search}})
          } else {
            this.$router.replace({'path': `/${this.selected.category}`, 'query': {'category': category, 'search': this.search}})
          }
          // this.$router.replace({'query': {'search': this.search}})
          // router.push({ path: 'page', query: { search: this.search } })
        } else if (this.$route.query.search !== undefined && this.search === '') {
          if (this.$route.query.category === undefined) {
            this.$router.replace({'path': `/${this.selected.category}`})
          } else {
            this.$router.replace({'path': `/${this.selected.category}`, 'query': {'category': category}})
          }
        }
      },
      toFilterCategory() {
        if(this.$route.query.search === undefined) {
          // this.$router.replace({'path': '/page', 'query': {'category': `/${this.selected.category}`}})
          this.$router.replace({'path': `/${this.selected.category}`})
        } else {
          // this.$router.replace({'path': '/page', 'query': {'category': `/${this.selected.category}`, 'search': this.search}})
          this.$router.replace({'path': `/${this.selected.category}`, 'query': {'search': this.search}})
        }
      },
      filterSubMenu(name, link) {
        // if(name === '') {
        //   if (this.$route.query.search !== undefined) {
        //     this.$router.replace({'path': link, 'query': {'search': this.$route.query.search}})
        //   } else {
        //     this.$router.replace({'path': link})
        //   }
        // } else {
          // console.log('cobaaa')
          // router.push({ path: 'page', query: { search: null } })
          if (this.$route.query.search === undefined) {
            this.$router.replace({'path': `/${link}`, 'query': {'category': name}})
          } else {
            this.$router.replace({'path': `/${link}`, 'query': {'category': name, 'search': this.$route.query.search}})
          }
          // this.$router.replace({'query': {'search': this.$route.query.search}})
          // router.push({ path: 'page', query: { search: this.$route.query.search } })
        // }
      },
      toList(link) {
        this.$router.push(`${link}`)
      },
      goTo (item) {
        if (item.type === 'logout') {
          Cookie.remove('token')
          Cookie.remove('user')
          Cookie.remove('fcm')
          Cookie.remove('notif')

          window.location = '/admin/auth/login'
        } else {
          this.$router.push('/admin/profil')
        }
      },
      goBack () {
        if (this.$route.name === 'reservasi-slug') {
          this.$router.push('/reservasi')
        } else if (this.$route.name === 'berita-slug') {
          this.$router.push('/berita')
        } else {
          this.$router.go(-1)
        }
      }
    }
  }
</script>

<style>
  .header {
    /* background-color: transparent !important; */
  }
  .header-default {
    background-color: #a20000 !important;
  }
  .header-detail {
    background-color: transparent !important;
  }
  html:not([data-scroll="0"]) .header {
    /* background: url(@/assets/background/bg_basic.png); */
    /* background-color: #00000040 !important; */
    /* background-color: #a20000 !important; */
    background-repeat: no-repeat;
    background-position: 100%;
    background-size: cover;
    /* opacity: 80% !important; */
  }
  .header .menu {
    /* color: #FFFFFF !important; */
    background-color: transparent !important;
    /* opacity: 60%; */
  }

  .sub .v-list-item {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
  background: #f9f9f9 !important;
  box-shadow: none !important;
  border: 1px solid #ebebeb;
  /* border-radius: 6px; */
  margin-bottom: 0px !important;
}

.theme--light.v-text-field--solo.v-input--is-focused > .v-input__control > .v-input__slot {
  background: #f9f9f9 !important;
  /* border: 0.25px solid #4ab762; */
  border-width: 0.25px !important;
  /* border-radius: 6px; */
  margin-bottom: 0px !important;
}

.theme--light.v-text-field--solo.v-input--is-focused > .v-input__control > .v-input__slot {
  background: #f9f9f9 !important;
  /* border: 0.25px solid #4ab762; */
  border-width: 0.25px !important;
  /* border-radius: 6px; */
  margin-bottom: 0px !important;
}

.error_input_border.v-input--is-focused > .v-input__control > .v-input__slot {
  background: #f9f9f9 !important;
  border: 0.25px solid red !important;
  /* border-radius: 6px; */
  margin-bottom: 0px !important;
}

.input-border-right-none.v-input--is-focused > .v-input__control > .v-input__slot {
  border-radius: 6px 0px 0px 6px !important;
}

.border-input-none.v-input--is-focused > .v-input__control > .v-input__slot {
  border-width: none !important;
}

.border-input-none > .v-input__control > .v-input__slot {
  border: none !important;
}

.border-input-none > .v-input__control > .v-input__slot > .v-input__prepend-inner{
  opacity: 0.6;
}

.border-input-none > .v-input__control > .v-input__slot > .v-text-field__slot{
  opacity: 0.6;
}

.input-border-right-none > .v-input__control > .v-input__slot {
  border-radius: 6px 0px 0px 6px !important;
}

.v-text-field--outlined > .v-input__control > .v-input__slot {
  background: #FFFFFF !important;
  box-shadow: none !important;
  border-radius: 6px !important;
  margin-bottom: 0px !important;
}
</style>
