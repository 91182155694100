<template>
  <div
    class="fill-height">
    <v-layout wrap>
      <!-- style="padding-bottom: 900px; padding-top: 128px;" -->
      <v-flex>
        <section>
          <!-- <v-container>
            <v-row>
              <v-col
                cols="12">
                <v-card
                  flat
                  class="background-default">
                  <v-card-title
                    class="text-h4 white--text justify-center mb-2 pt-6"
                    style="font-weight: 500;">
                    PROFIL PEJABAT
                  </v-card-title>

                  <v-card-subtitle
                    class="body-1 white--text text-center mb-4"
                    style="opacity: 50%; font-weight: 500;">
                    Mengenal Profil Pejabat Kabupaten Klaten.
                  </v-card-subtitle>

                </v-card>
              </v-col>
            </v-row>

          </v-container> -->


          <v-card
          flat
          width="100%"
          class="pa-5" v-if="process.load">
          <v-skeleton-loader
              v-if="process.load"
              :loading="process.load"
              transition="scale-transition"
              :tile="false"
              class="ma-auto load_card_x_large"
              type="image"
              style="width: 100% !important; height: 60vh !important; min-height: 60vh !important;">
            </v-skeleton-loader>
          </v-card>
          <v-row>
            <v-col
            v-if="!process.load"
            cols="12" class="py-0">
            <v-card
              flat>
              <v-row justify="space-around">
                <v-col
                  cols="12"
                  sm="10"
                  md="8"
                >
                  <v-sheet
                    elevation="0"
                    class="py-4 px-1"
                  >
                    <v-chip-group
                      mandatory
                      v-model="selected.tag"
                      :color="set_color"
                    >
                      <v-chip
                        v-for="(tag, index) in tag"
                        :key="index"
                        class="px-6"
                      >
                        {{ tag }}
                      </v-chip>
                    </v-chip-group>
                  </v-sheet>
                </v-col>
              </v-row>

                <v-card-text
                class="body-1 line-text-second mb-4"
                v-if="list[selected.tag]">
                <!-- {{ list[selected.tag].image_url }} -->
                <!-- v-for="(item, index) in list" :key="index" -->
                  <!-- <div class="text-center font-weight-bold text-h5 mt-4" :style="`color: ${set_color}`">
                    <span v-if="item.birthplace === '1'">Profil Bupati Klaten</span>
                    <span v-if="item.birthplace === '2'">Profil Wakil Bupati Klaten</span>
                  </div> -->
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-img
                        :src="list[selected.tag].image_url"
                        :lazy-src="list[selected.tag].image_url"
                        width="250"
                        alt="Kabupaten Klaten"
                        contain
                        class="rounded-lg mx-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>
                    <v-col cols="12" md="8">
                      <!-- <div style="text-indent: 50px;">
                        Sejarah Klaten tersebar diberbagai catatan arsip-arsip kuno dan kolonial, arsip-arsip kuno dan manuskrip Jawa. Catatan itu seperti tertulis dalam Serat Perjanjian Dalem Nata, Serat Ebuk Anyar, Serat Siti Dusun, Sekar Nawala Pradata, Serat Angger Gunung, Serat Angger Sedasa dan Serat Angger Gladag. Dalam bundel arsip Karesidenan Surakarta menjadikan rujukan sejarah Klaten seperti tercantum dalam Soerakarta Brieven van Buiten Posten, Brieven van den Soesoehoenan 1784-1810, Daghregister van den Resi dentie Soerakarta 1819, Reporten 1787-1816, Rijksblad Soerakarta dan Staatblad van Nederlandsche Indie. Babad Giyanti, Babad Bedhahipun Karaton Negari Ing Ngayogyakarta, Babad Tanah Jawi dan Babad Sindula menjadi sumber lain untuk menelusuri sejarah Klaten.
                      </div> -->
                      <div
                        class="mb-6 mt-10">
                        <div class="title">
                          Profil
                        </div>
                          Nama : {{ list[selected.tag].name }}
                        <div>
                          Tempat/Tanggal Lahir : {{ list[selected.tag].birthplace }}/{{ list[selected.tag].birthdate | date }}
                        </div>
                      </div>

                      <div
                        class="mb-6"
                        v-if="JSON.parse(list[selected.tag].education_json).length > 0">
                        <div class="title">
                          Riwayat Pendidikan
                        </div>
                        <ol>
                          <li v-for="(l, i) in JSON.parse(list[selected.tag].education_json)" :key="i">{{ l }}</li>
                        </ol>
                      </div>

                      <div
                        class="mb-6"
                        v-if="JSON.parse(list[selected.tag].organization_json).length > 0">
                        <div class="title">
                          Riwayat Organisasi
                        </div>
                        <ol>
                          <li v-for="(l, i) in JSON.parse(list[selected.tag].organization_json)" :key="i">{{ l }}</li>
                        </ol>
                      </div>

                    </v-col>
                    <v-col cols="12" md="12">
                      <div
                        class="mb-6"
                        v-if="JSON.parse(list[selected.tag].position_json).length > 0">
                        <div class="title">
                          Riwayat Jabatan
                        </div>
                        <ol>
                          <li v-for="(l, i) in JSON.parse(list[selected.tag].position_json)" :key="i">{{ l }}</li>
                        </ol>
                      </div>

                      <div
                        class="mb-6"
                        v-if="JSON.parse(list[selected.tag].penghargaan_json).length > 0">
                        <div class="title">
                          Penghargaan
                        </div>
                        <ol>
                          <li v-for="(l, i) in JSON.parse(list[selected.tag].penghargaan_json)" :key="i">{{ l }}</li>
                        </ol>
                      </div>

                    </v-col>
                  </v-row>

                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        list: [],
        process: {
          load: false
        },
        tag: ['Bupati', 'Wakil Bupati'],
        selected: {
          tag: 0
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/profil?menu=pejabat',
          title: `Profil Pejabat - ${this.$store.state.member.unit.unit.title} Kabupaten Klaten`,
          description: `Profil Pejabat ${this.$store.state.member.unit.unit.title} Kabupaten Klaten`,
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {

    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      },
      set_color_rgb_down () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.25)`
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch () {
        this.process.load = true
        await this.$axios.$get(`${process.env.API}sites/profile/leader`)
        .then(response => {
          let res = response

          if (res.status === 200) {
            this.list = res.results.data

              this.process.load = false
          }
        })
      }
    }
  }
</script>
