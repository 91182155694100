<template>
  <div
    class="fill-height">

  <!-- <div class="font-weight-medium mb-2 mt-2" :style="`color: ${set_color}`" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'text-h5' : 'body-1'">
    Daftar Pejabat
  </div> -->

  <v-card
      flat>
      <v-card-text :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '' : 'px-0'">
        <div>
          <v-skeleton-loader
            v-if="process.load"
            :loading="process.load"
            transition="scale-transition"
            :tile="false"
            class="ma-auto load_card_x_large"
            type="image"
            style="width: 100% !important; height: 60vh !important; min-height: 60vh !important;">
          </v-skeleton-loader>
        </div>

        <div v-if="!process.load && list.length > 0">
          <div class="mt-1">
            <v-card flat class="px-3">
              <v-card-title class="px-0 pt-0">
                <div>
                  <div>
                    Daftar Pejabat {{ list[0].title }}
                  </div>

                  <div class="mb-5">
                    <a
                      class="body-1"
                      :href="list[0].website"
                      target="_blank"
                      :style="`color: ${set_color}`"
                      style="opacity: 60%;">
                      <u>{{ list[0].website }}</u>
                    </a>
                  </div>
                </div>
                <v-spacer></v-spacer>
                <a
                :href="`https://www.google.com/maps/search/?api=1&query=${list[0].latitude},${list[0].longitude}`"
                style="color:#0000FF;text-align:left"
                  target="_blank">
                    <iframe
                      width="300"
                      height="170"
                      frameborder="0"
                      scrolling="no"
                      marginheight="0"
                      marginwidth="0"
                      :src="`https://maps.google.com/maps?q=${list[0].latitude},${list[0].longitude}&hl=es&z=14&amp;output=embed`"
                      >
                    </iframe>
                  </a>
                </v-card-title>
                <v-text-field
                  v-model="search"
                  solo
                  append-icon="mdi-magnify"
                  label="Cari"
                  class="mt-4 mb-2"
                  :color="set_color"
                  style="border-radius: 6px 0px 0px 6px !important;"
                  single-line
                  hide-details
                  ></v-text-field>
                <div v-if="list[0].child.length > 0">
                  <v-data-table
                    :headers="headers"
                    :items="list[0].child"
                    :search="search"
                    class="table-report"
                  ></v-data-table>
                </div>
                <div v-if="list[0].child.length < 1">
                  <div v-if="process.table">
                    <v-skeleton-loader
                      class="ma-auto"
                      v-if="process.table"
                      :loading="process.table"
                      type="table">
                    </v-skeleton-loader>
                  </div>
                  <div v-if="!process.table">
                    <v-data-table
                      :headers="headers"
                      :items="list[0].child"
                      :search="search"
                      item-key="id"
                      class="table-report"
                    >
                    </v-data-table>
                  </div>
                </div>
            </v-card>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { createSEOMeta } from '@/utils/seo'
import VueTree from "@ssthouse/vue-tree-chart";
import OrganizationChart from "vue-organization-chart";
import "vue-organization-chart/dist/orgchart.css";

export default {
  name: "mind-map",
  components: { VueTree, OrganizationChart },
  data() {
    return {
      openList: '',
      detail : false,
      type: 'bagan',
      headers: [],
      dialog: {
          detail: false
        },
        search: '',
        list: [],
        list_new: [],
        daftar: [],
        process: {
          load: false,
          table: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/profil?menu=darfat-pejabat',
          title: `Daftar Pejabat - ${this.$store.state.member.unit.unit.title} Kabupaten Klaten`,
          description: `Daftar Pejabat ${this.$store.state.member.unit.unit.title} Kabupaten Klaten`,
          image: require('@/assets/logo/favicon.png')
        }
    };
  },
  head () {
    return {
      title: this.content.title,
      meta: createSEOMeta(this.content)
    }
  },
  computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      },
      unit () {
        return this.$store.state.member.unit.unit
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      },
      set_color_rgb_down () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.25)`
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      voiceHover (item) {
        responsiveVoice.cancel(); // Cancel anything else that may currently be speaking
        responsiveVoice.speak(item, 'Indonesian Female');
      },
      // async fetch () {
      //   this.process.load = true
      //   let params = {
      //     limit: 10000000000000000,
      //     par_id: '0'
      //   }
      //   await this.$axios.$get(`${process.env.API}sites/profile/unit`, { params })
      //   .then(response => {
      //     let res = response

      //     if (res.status === 200) {
      //       this.list = []
      //       this.daftar = res.results.data

      //       for(let i = 0; i < res.results.data.length; i++){
      //         if(res.results.data[i].par_id === '0') {
      //           this.list.push(res.results.data[i])
      //         }
      //       }

      //         this.process.load = false
      //     }
      //   })
      // }

      async fetch (type) {
        this.process.load = true

         let params = {
          limit: 10000000,
          par_id: '0',
          id: this.$store.state.member.unit.unit.id
        }

        await this.$axios.$get(`${process.env.API}sites/profile/unit`, { params })
        .then(response => {
          let res = response

          if (res.status === 200) {
            this.list = []
            let data = res.results.data
            data.map((obj) => {
              this.list.push({
                id: obj.id,
                par_id: obj.par_id,
                title: obj.title,
                latitude: obj.latitude,
                longitude: obj.longitude,
                website: obj.website,
                type: obj.type,
                child: []
              })
            })
            this.DataTable(0)
            this.process.load = false
          }
        })
      },

      async fetchDataTable (index) {
        this.process.table = true
        setTimeout(() => {
          // console.log('ada')
          // for( let i in this.openList) {
          //   if(this.openList[i] === index) {
          //     this.DataTable(index)
          //   } else {
          //     this.process.table = false
          //   }
          // }

          if(this.openList === index) {
            this.DataTable(index)
          } else {
            this.process.table = false
          }
        }, 1000)

      },

      async DataTable (indexpejabat) {
        this.process.table = true

          let params = {
          limit: 10000000,
          unit_id: this.$store.state.member.unit.unit.id
        }

        await this.$axios.$get(`${process.env.API}sites/user_position/data`, { params })
        .then(response => {
          let res = response

          if (res.status === 200) {

            this.headers = [
              // {
              //   text: 'Aksi',
              //   width: '140px',
              //   value: 'id',
              //   freeze: true,
              //   hide: false,
              //   align: 'center',
              //   format: 'action'
              // },
              { text: 'No', value: 'num', sortable: false },
              {
                text: 'Nama',
                width: '300px',
                value: 'user_name',
                // freeze: false,
                // hide: false
              },
              {
                text: 'Jabatan',
                width: '350px',
                value: 'title',
                // freeze: false,
                // hide: false
              },
              {
                text: 'Eselon',
                width: '150px',
                value: 'eselon',
                // freeze: false,
                // hide: false
              },
              {
                text: 'Golongan',
                width: '120px',
                value: 'type',
                // freeze: false,
                // hide: false
              },
              {
                text: 'Unit / Divisi',
                width: '300px',
                value: 'unit_title',
                // freeze: false,
                // hide: false
              },
              {
                text: 'Pendidikan Terakhir',
                width: '200px',
                value: 'education',
                // freeze: false,
                // hide: false
              },
              {
                text: 'Deskripsi Jabatan',
                width: '400px',
                value: 'job_desc',
                // freeze: false,
                // hide: false
              },
              // {
              //   text: 'Jenis Kelamin',
              //   width: '200px',
              //   value: 'gender',
              //   freeze: false,
              //   hide: false
              // },
              // {
              //   text: 'Alamat',
              //   width: '300px',
              //   value: 'address',
              //   freeze: false,
              //   hide: false
              // },
              // {
              //   text: 'Nama Bank',
              //   width: '200px',
              //   value: 'account_bank_name',
              //   freeze: false,
              //   hide: false
              // },
              // {
              //   text: 'Nomor Rekening',
              //   width: '200px',
              //   value: 'account_number',
              //   freeze: false,
              //   hide: false
              // },
              // {
              //   text: 'Atas Nama Rekening',
              //   width: '200px',
              //   value: 'account_name',
              //   freeze: false,
              //   hide: false
              // },
              // {
              //   text: 'Presensi Datang Jam',
              //   width: '200px',
              //   value: 'presensi_start_time',
              //   freeze: false,
              //   hide: false
              // },
              // {
              //   text: 'Presensi Pulang Jam',
              //   width: '200px',
              //   value: 'presensi_end_time',
              //   freeze: false,
              //   hide: false
              // },
              // {
              //   text: 'Bergabung Tanggal',
              //   width: '200px',
              //   value: 'join_date',
              //   freeze: false,
              //   hide: false,
              //   format: 'date'
              // },
              // {
              //   text: 'Dibuat Tanggal',
              //   width: '200px',
              //   value: 'create_datetime',
              //   freeze: false,
              //   hide: false,
              //   format: 'datetime'
              // }
            ]

            this.list[indexpejabat].child = []
            let data = res.results.data

            data.map((obj, index) => {
              this.list[indexpejabat].child.push({
                id: obj.id,
                par_id: obj.par_id,
                unit_id: obj.unit_id,
                unit_title: obj.unit_title,
                user_name: obj.user_name === '' ? 'KOSONG' : obj.user_name,
                type: obj.type === '' ? 'KOSONG' : obj.type,
                job_desc: obj.job_desc === '' ? 'KOSONG' : obj.job_desc,
                title: obj.title === '' ? 'KOSONG' : obj.title,
                eselon: obj.eselon === '' ? 'KOSONG' : obj.eselon,
                education: obj.education === '' ? 'KOSONG' : obj.education,
                num: index + 1
              })
            })

            // this.list[index].child = res.results.data

            console.log(this.list[indexpejabat].child)

            this.process.table = false
          }
        })
      },


      changeData (data_array) {
        function filter (array, condition) {
          let result = []

          for (let i = 0; i < array.length; i++) {
            if (condition(array[i])) {
              result.push(array[i])
            }
          }

          return result
        }

        function getChilds (parentKey, items) {
          let subItems = filter(items, function (n) {
            return n.unit_id === parentKey
          })

          let result = []

          for (let i = 0; i < subItems.length; i++) {
            let subItem = subItems[i]

            let kids = getChilds(subItem.unit_id, items)
            console.log(subItem)
            if (kids.length) {
              subItem.children = kids
            }

            result.push(subItem)
          }

          if (parentKey.length) {
            return result
          } else {
            return result[0]
          }
        }

        const data = data_array

        let richMediaData = getChilds('0', data)

        return richMediaData
      },
    }
};
</script>

<style>
.mind-map-layout {
  width: 100%;
  height: 100%;
  border: 1px solid #efefef;
}

.tree-container > svg[data-v-56c15873], .tree-container .dom-container[data-v-56c15873] {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50% !important;
    top: 0;
    overflow: visible;
    transform-origin: 0 50%;
}
.tree-container[data-v-56c15873] {
    position: relative;
    overflow: scroll !important;
}

.orgchart .node {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: inline-block;
    position: relative;
    margin: 0;
    padding: 3px;
    border: 2px dashed transparent;
    text-align: center;
    width: auto !important;
}

.orgchart-container {
    position: relative;
    display: inline-block;
    height: 420px;
    width: calc(100% - 24px);
    border: none !important;
    border-radius: 5px;
    overflow: auto;
    text-align: center;
}

.v-row-group__header {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
  }
  .table-report table > thead > tr > th {
    position: sticky !important;
    z-index: 1;
    top: 0;
  }
  .table-report  table > thead > tr > th:nth-child(1) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    z-index: 3 !important;
    /* background: white; */
  }
  .table-report  table > tbody > tr > td:nth-child(1) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    z-index: 1;
    background: white;
  }

  .table-report  table > thead > tr > th:nth-child(2) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 48.88px;
    z-index: 3 !important;
    /* background: white; */
  }
  .table-report  table > tbody > tr > td:nth-child(2) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 48.88px;
    z-index: 1;
    background: white;
  }

  .theme--light.v-data-table table > tbody > tr > td {
    /* background: #FFFFFF !important; */
    /* border: 0px !important; */
    padding-top: 8px !important;
    padding-bottom: 8px !important;

  }
</style>
