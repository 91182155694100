 <template>
  <div
    class="fill-height"
    :style="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? `padding-bottom: 280px; padding-top: 128px;` : `padding-bottom: 650px; padding-top: 64px;`">
    <v-layout wrap>
      <v-flex>
        <section>
          <div>
            <!-- <v-card
              v-if="process.load"
              flat
              height="250"
              width="100vw"
              color="#00000040">
              <v-skeleton-loader
                :loading="process.load"
                transition="scale-transition"
                :tile="false"
                class="ma-auto load_card_x_large"
                type="image"
                style="width: 100% !important; height: 100% !important; min-height: 100% !important;">
              </v-skeleton-loader>
            </v-card> -->
            <div v-if="!process.load">
                <!-- <v-card
                  flat
                  cover
                  v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' "
                  class="background-default">
                  <v-img :src="require('@/assets/image/Candi-Prambanan.jpg')" width="100%" height="250" style="opacity: 0.75;" :gradient="`to right, ${set_color_rgb_up}, #000000a6`" class="img_center align-center">

                    <div class="d-flex align-center justify-center font-weight-black">
                      <div class="text-center">
                        <div
                          class="text-h4 white--text justify-center mb-2 pt-6"
                          style="font-weight: 500;">
                          {{ category[selected.category].category_title }} <span v-if="selected.category === 0">Berita</span>
                        </div>

                        <div
                          class="body-1 white--text text-center mb-4"
                          style="opacity: 50%; font-weight: 500;">
                          Mengenal {{ category[selected.category].category_title }} <span v-if="selected.category === 0">Berita</span> di kabupaten Klaten.
                        </div>
                      </div>
                    </div>

                  </v-img>
                </v-card> -->
            </div>
          </div>
          <v-container>
            <!-- <v-row>
              <v-col
                cols="12">
                <v-card
                  flat
                  class="background-default">
                  <v-card-title
                    class="text-h4 white--text justify-center mb-2 pt-6"
                    style="font-weight: 500;">
                    Berita
                  </v-card-title>

                  <v-card-subtitle
                    class="body-1 white--text text-center mb-4"
                    style="opacity: 50%; font-weight: 500;">
                    Jangan ketinggalan berita yang ada di Klaten.
                  </v-card-subtitle>

                  <v-card-text>
                    <v-row
                      class="mb-5"
                      justify="center">
                      <v-col
                        cols="6">
                      </v-col>
                      <v-col
                        cols="6">
                        <v-text-field
                          v-model="search"
                          @click:clear="search = ''; fetch();"
                          v-on:keyup.enter="fetch()"
                          dense
                          rounded
                          solo
                          single-line
                          placeholder="Cari data yang Anda butuhkan . . ."

                          hide-details
                          clearable

                          class="form_white"
                          :color="set_color"
                          append-icon="mdi-magnify">
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row> -->

            <div>
              <v-row>
                <!-- <v-col cols="12" md="3">
                  <v-skeleton-loader
                    :loading="process.load"
                    v-if="process.load"
                    transition="scale-transition"
                    :tile="false"
                    class="ma-auto load_card_x_large"
                    type="image"
                    style="width: 100% !important; height: 800px !important; min-height: 800px !important;">
                  </v-skeleton-loader>
                  <v-card
                    class="mx-auto"
                    v-if="!process.load"
                    style="border-radius: 15px;"
                    min-height="100%"
                    :class="'elevation-0'"
                    >
                    <v-card-title>
                      <div class="font-weight-medium" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'  ? 'text-h6' : 'body-1 text-center w-100'">
                        <span>Kategori</span> Berita
                      </div>
                    </v-card-title>
                    <v-list flat v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'">
                      <v-list-item-group
                        v-model="selected.category"
                        mandatory
                        :color="set_color"
                      >
                        <v-list-item
                          v-for="(item, i) in category"
                          :key="i"
                          @click="selected.category = i; changeQuery(item.category_name); fetch(item.id)"
                        >
                          <v-list-item-icon class="mr-2">
                            <v-img
                              width="40px"
                              alt="Kabupaten Klaten"
                              :src="item.category_icon_url === '' ? require('@/assets/image/no-photo.png') : item.category_icon_url">
                            </v-img>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title>{{ item.category_title }}</v-list-item-title>
                          </v-list-item-content>

                          <v-list-item-action class="ml-0 my-0">
                            <v-chip
                              class="ma-2"
                              small
                              style="min-width: 40px; text-align: center; justify-content: center;"
                              text-color="white"
                              :color="`${set_color}`"
                            >
                              {{ item.count }}
                            </v-chip>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                    <v-slide-group
                      v-model="selected.category"
                      class="px-0 pb-5"
                      mandatory
                      show-arrows
                      v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'"
                    >
                      <v-slide-item
                        v-for="n in category"
                        :key="n"
                        v-slot="{ active }"
                      >
                      <v-btn
                          class="mx-2 text-capitalize"
                          :input-value="active"
                          :color="active ? `${set_color}` : ``"
                          :active-class="`white--text`"
                          depressed
                          rounded
                          @click="selected.category = i; changeQuery(n.category_name); fetch(n.id)"
                        >
                          {{ n.category_title }}
                        </v-btn>
                      </v-slide-item>
                    </v-slide-group>
                  </v-card>
                </v-col> -->
                <v-col cols="12" md="9" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'pt-0' : ''">
                  <div v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'" style="background-color: #fff;" class="pa-3">
                    <v-autocomplete
                      v-model="filter.category"
                      :items="category"
                      item-text="category_title"
                      item-value="id"
                      hide-no-data
                      hide-details
                      dense
                      solo
                      single-line
                      placeholder="Pilih Kategori"
                      :color="set_color"
                      @change="changeQuery(category.find(item => item.id === filter.category).category_name); fetch(filter.category)"
                      class="form_white"
                      append-icon="mdi-chevron-down">
                    </v-autocomplete>
                  </div>
                  <v-row>
                    <v-col
                      v-for="(item, index) in process.load || process.list ? 8 : list"
                      :key="index"
                      :cols="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 6 : 12"
                      md="4">
                      <v-skeleton-loader
                        :loading="process.load || process.list"
                        transition="scale-transition"
                        :tile="false"
                        class="ma-auto"
                        type="card">
                        <div>
                          <div v-if="!process.list && !process.load && list.length > 0"
                            class="border-radius">
                            <v-card
                              @click="toDetail(item)"
                              @mouseover="voiceHover(item.title)"
                              style="border-radius: 6px; box-shadow: rgba(0, 0, 0, 0.01) 0px 3px 5px;"
                              width="100%">
                              <v-img
                                :height="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'  ? 130 : 200"
                                alt="Pemerintah Kabupaten Klaten"
                                style="border-radius: 6px;"
                                :src="item.image_url_cover === '' ? item.image_url === '' ? require('@/assets/image/no-photo.png') : item.image_url : item.image_url_cover"
                                class="ma-auto">
                                <!-- <v-card flat
                                  color="transparent"
                                  class="d-flex align-center pa-3"
                                  style="border-radius: 50px;">
                                  <v-avatar size="20">
                                    <v-img
                                      alt="Pemerintah Kabupaten Klaten"
                                      :src="item.post_extra.createdby.image"
                                      class="ma-auto">
                                      <template v-slot:placeholder>
                                        <v-row
                                          class="fill-height"
                                          align="center"
                                          justify="center">
                                          <v-progress-circular
                                            indeterminate
                                            :color="set_color"
                                            size="15">
                                          </v-progress-circular>
                                        </v-row>
                                      </template>
                                    </v-img>
                                  </v-avatar>

                                  <div
                                    class="caption text-capitalize font-weight-bold one-line white--text pl-2">
                                    {{ item.post_extra.createdby.name }}
                                  </div>
                                </v-card> -->

                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>

                              <div class="pa-3">
                                <div class="" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-2 font-weight-bold one-line' : 'fs-12 font-weight-bold two-line'">
                                  {{ item.title }}
                                </div>

                                <div class="d-flex align-center mt-1">
                                  <v-icon small color="#212121">mdi-account-circle-outline</v-icon>

                                  <span class="fs-11 one-line ml-1">
                                    <!-- {{ item.autor }} -->Admin
                                  </span>

                                </div>

                                <div class="fs-11 font-weight-light three-line my-2" style="opacity: 60%; min-height: 50px; height: 50px; max-height: 50px;">
                                  <span v-html="item.content.replace(/(<([^>]+)>)/ig, '')"></span>
                                </div>

                                <!-- <div class="">
                                  <v-chip small class="ma-1 ml-0" style="font-size: 8px;" v-for="(tag, i) in item.tag" :key="i">{{ tag }}</v-chip>
                                </div> -->

                                <v-divider class="my-2" style="border: 2px solid #f9f9f9;" />

                                <div class="fs-11 d-flex align-center" style="opacity: 60%;">
                                  <div>
                                    {{ item.publish_datetime | date }}
                                  </div>

                                  <!-- <v-spacer />

                                  <div>
                                    {{ item.post_create_datetime | time }}
                                  </div> -->
                                </div>
                              </div>
                            </v-card>
                          </div>
                        </div>
                      </v-skeleton-loader>
                    </v-col>
                  </v-row>
                  <div v-if="!process.load && pagination.total_data > 12 && limit < pagination.total_data"
                    class="text-center mt-4 mb-2">
                    <v-btn
                      @click="limit += 12"
                      :loading="process.limit"
                      small
                      rounded
                      outlined
                      elevation="0"
                      @mouseover="voiceHover('Lebih Banyak')"
                      :color="set_color"
                      class="text-capitalize"
                      style="opacity: 60%;">
                      Lebih banyak
                    </v-btn>
                  </div>

                  <Empty v-if="!process.load && pagination.total_page < 1"
                    class="py-12"
                    margin="my-16"
                    size="125"
                    message="Berita" />

                </v-col>
                <v-col cols="12" md="3" v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'">
                  <v-card flat style="border-radius: 6px;" class="mb-6 mt-0">
                    <v-card-text>
                      <div class="font-weight-medium body-1" :style="`color: ${set_color}`">
                        Kategori
                      </div>
                      <v-divider class="mb-0 mt-2"></v-divider>
                      <div v-for="(item, index) in process.load ? 8 : category" :key="index">
                        <v-skeleton-loader
                          class="ma-auto"
                          :loading="process.load"
                          type="list-item-three-line">
                          <div>
                            <div v-if="!process.load && category.length > 0" @mouseover="voiceHover(item.category_title)">
                              <v-list one-line>
                                <v-list-item class="pa-0" @click="selected.category = index; changeQuery(item.category_name); fetch(item.id)">
                                    <v-list-item-content class="py-0" :style="selected.category === index ? `color: ${set_color}; opacity: 65%;` : ''">
                                      <v-list-item-title>{{ item.category_title }}</v-list-item-title>
                                    </v-list-item-content>

                                    <!-- <v-list-item-action class="my-0">
                                      <v-chip
                                        class="ma-2"
                                        small
                                        style="min-width: 40px; text-align: center; justify-content: center;"
                                        text-color="white"
                                        :color="`${set_color}`"
                                      >
                                        {{ item.count }}
                                      </v-chip>
                                    </v-list-item-action> -->
                                </v-list-item>

                              </v-list>
                              <v-divider
                                v-if="index < 10 - 1"
                              ></v-divider>
                            </div>
                          </div>
                        </v-skeleton-loader>
                      </div>
                    </v-card-text>
                  </v-card>

                  <Sidebar />
                </v-col>
              </v-row>
            </div>


          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        search: '',
        category: [
          {
            icon: 'mdi-newspaper',
            text: 'Berita',
            link: '/berita?category=utama'
          },
          {
            icon: 'mdi-newspaper-variant',
            text: 'Berita OPD',
            link: '/berita?category=opd'
          },
          {
            icon: 'mdi-newspaper-variant-outline',
            text: 'Berita Wilayah',
            link: '/berita?category=wilayah'
          },
        ],
        selected: {
          category: 0
        },
        filter: {
          category: ''
        },
        total_category: 0,
        list: [],
        pagination: {},

        limit: 12,

        process: {
          load: false,

          limit: false,

          list: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/berita',
          title: `Berita - ${this.$store.state.member.unit.unit.title} Kabupaten Klaten`,
            description: `Berita ${this.$store.state.member.unit.unit.title} Kabupaten Klaten`,
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    // components: {
    //   Utama: () => import(/* webpackPrefetch: true */ '@/components/berita/utama.vue'),
    //   OPD: () => import(/* webpackPrefetch: true */ '@/components/berita/opd.vue'),
    //   Wilayah: () => import(/* webpackPrefetch: true */ '@/components/berita/wilayah.vue'),
    // },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty'),
      Sidebar: () => import(/* webpackPrefetch: true */ '@/components/sidebar/index')
    },
    watch: {
      'limit': function() {
        this.process.limit = true

        this.fetch('limit')
      },
      '$route.query.category': function(val) {
        this.changeLink(val)
      },
      '$route.query.search': function(val) {
        this.changeLink()
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        console.log(this.$store.state.header.config.color)
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      },
      set_color_rgb_down () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.25)`
      }
    },
    mounted () {
      this.fetchCategory()
    },
    methods: {
      voiceHover (item) {
        responsiveVoice.cancel(); // Cancel anything else that may currently be speaking
        responsiveVoice.speak(item, 'Indonesian Female');
      },
      async fetchCategory (id, type) {
        this.process.load = type === undefined ? true : false

        let params = {
          limit: 1000000,
          par_id: '1',
          unit_id: this.$store.state.member.unit.unit.id
        }

        await this.$axios.$get(`${process.env.API}sites/article/category`, { params })
        .then(response => {
          let res = response

          if (res.status === 200) {
            this.category = [
              {
                category_title: "Semua Kategori",
                category_icon_url: require('@/assets/icon/app.png'),
                id: '',
                category_name: "semua-kategori",
                count: 0
              }
            ]
            let category = []
            category = res.results.data.map(item => {
                const container = {};

                container.category_title = item.title;
                container.category_icon_url = item.icon_url;
                container.id = item.id;
                container.category_name = item.slug;
                container.count = item.count

                // this.category = container;
                return container
              })


            for (let i in category) {
              this.category.push(
                {
                  category_title: category[i].category_title,
                  category_icon_url: category[i].category_icon_url,
                  id: category[i].id,
                  category_name: category[i].category_name,
                  count: category[i].count
                }
              )

            }

            for (let i in this.category) {
              this.total_category += parseInt(this.category[i].count)

            }

            this.process.load = false
            this.category[0].count = this.total_category
            this.changeLink()
          }
        })
      },
      async fetch () {
        this.process.list = this.process.limit ? false : true

        let params = {
          search: this.$route.query.search === undefined ? '' : this.$route.query.search,
          limit: this.limit,
          'category_id[neq]': '2',
          // filter: `par_id!=2`,

          // sort: 'publish_datetime',

          category_id: this.filter.category,
          unit_id: this.$store.state.member.unit.unit.id
        }

        await this.$axios.$get(`${process.env.API}sites/article/data`, { params })
        .then(response => {
          let res = response


          this.process.limit = false

          if (res.status === 200) {
            this.list = res.results.data
            this.pagination = res.results.pagination

          }
          this.process.list = false
          this.process.load = false
        })
      },
      changeLink() {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
        let status = false
        let id = 0
        this.selected.category = 0
        this.filter.category = ''


        if (this.$route.query.category !== undefined) {
          for(let i = 0; i < this.category.length; i++){
            if (this.category[i].category_name === this.$route.query.category) {
              this.filter.category = this.category[i].id
              this.selected.category = i
              status = true
            } else {
              // status = false
            }
          }
          // if (query === 'wisata') {
          //     this.selected = {
          //       category: 0,
          //     }
          // } else if (query === 'kuliner') {
          //   this.selected = {
          //     category: 1
          //   }
          // }
          // this.fetch(id)
        }
        // console.log(status)
        if (status === false) {
          this.$router.push(`/berita?category=${this.category[0].category_name}`)
        }

        console.log(id)

        this.fetch()
      },
      changeQuery(name) {
        if(name === '') {
          if (this.$route.query.search !== undefined) {
            this.$router.replace({'path': `/berita`, 'query': {'search': this.$route.query.search}})
          } else {
            this.$router.replace({'path': `/berita`})
          }
        } else {
          if (this.$route.query.search === undefined) {
            this.$router.replace({'path': `/berita`, 'query': {'category': name}})
          } else {
            this.$router.replace({'path': `/berita`, 'query': {'category': name, 'search': this.$route.query.search}})
          }
        }
      },
      toDetail(item) {
        this.$router.push(`/berita/${(item.title.split('-').join('=').split(' ').join('-').split('/').join('>'))}?q=${item.id}`)
      },
    }
  }
</script>
