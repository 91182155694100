<template>
  <div
    class="fill-height"
    :style="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? `padding-bottom: 280px; padding-top: 128px;` : `padding-bottom: 650px; padding-top: 64px;`">
    <v-dialog v-model="dialog.success"
      width="350">
      <v-card
        class="border-radius pa-2">
        <v-card-text>
          <v-img
            width="125"
            height="125"
            alt="Pemerintah Kabupaten Klaten"
            :src="require('@/assets/icon/info_berhasil.png')"
            class="ma-auto my-6">
            <template v-slot:placeholder>
              <v-row
                class="fill-height"
                align="center"
                justify="center">
                <v-progress-circular
                  size="20"
                  indeterminate
                  :color="set_color">
                </v-progress-circular>
              </v-row>
            </template>
          </v-img>

          <div
            class="body-1 text-center line-text-second">
            <div>
              Terima kasih, pengisian survey Anda telah berhasil
            </div>

            <!-- <div>
              telah berhasil diubah!
            </div> -->
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      persistent
      scrollable
      :fullscreen="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? true : false"
      v-model="dialog.layanan"
      :min-height="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? '' : 550"
      width="850">
      <v-card v-if="selected.category !== null" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'border-radius-none' : 'box-shadow rounded-xl'">
        <div class="d-flex align-center body-2" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'pa-4' : 'px-8 pb-3 pt-5'">
          <div>
            <div class="font-weight-bold" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'body-1' : 'title'">
              {{ selected.category.category_title }}
            </div>
            <div>
              {{ selected.category.category_desc }}
            </div>
          </div>
          <div class="ml-auto">
            <v-btn
              :width="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 20 : 25"
              :height="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 20 : 25"
              color="white"
              elevation="0"
              fab
              class="text-capitalize px-0"
              @click="dialog.layanan = false;">
              <v-icon size="25" class="color-default">mdi-close-circle-outline</v-icon>
            </v-btn>
          </div>
        </div>
        <v-divider
          style="border-top: 2px dashed #e0e0e0;"></v-divider>
                <div>
                    <v-card-title>
            <v-row justify="center">
              <v-col :cols="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 6 : 12" md="3" v-for="(item, index) in process.load ? 4 : selected.layanan"
              :key="index">
              <v-card
                flat
                height="185"
                @mouseover="voiceHover(item.services_title)"
                style="border-radius: 10px; border: 1px solid #E7E7E7;"
                class="ma-2 rounded-xl shadow-default"
              >
                <v-card-title class="justify-center pb-1 px-1">
                  <v-avatar size="50">
                    <v-img
                      width="50px"
                      alt="Kabupaten Klaten"
                      :src="item.services_icon_url === '' ? require('@/assets/image/no-photo.png') : item.services_icon_url">
                    </v-img>
                  </v-avatar>
                </v-card-title>
                <div>
                  <div class="text-center py-0 pt-0 pb-0 body-1 font-weight-medium px-1">
                    {{ item.services_title }}
                  </div>
                </div>
                <div class="text-center" v-if="selected.category.id !== '2'">
                  <a
                    class="caption"
                    :href="item.link_url"
                    target="_blank"
                    :style="`color: ${set_color}`"
                    style="opacity: 60%;">
                    <u>Link</u>
                  </a>
                </div>
                <v-card-text class="text-center py-0 pt-2 pb-2 caption px-1">
                  {{ item.services_desc }}
                </v-card-text>
              </v-card>
              </v-col>
            </v-row>
                    </v-card-title>
        </div>
            </v-card>
        </v-dialog>
    <v-dialog
      persistent
      scrollable
      :fullscreen="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? true : false"
      v-model="dialog.popup"
      width="500">
      <v-card :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'border-radius-none' : 'box-shadow'">
        <!-- <div class="d-flex align-center body-2" :class="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'? 'pa-4' : 'px-4 pb-3 pt-5'">
          <div>
          </div>
          <div class="ml-auto">
            <v-btn
              :width="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 20 : 25"
              :height="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 20 : 25"
              color="white"
              elevation="0"
              @click="dialog.popup = false; $store.commit('header/config/setPopup', 'after open')"
              fab
              class="text-capitalize px-0">
              <v-icon size="25" class="color-default">mdi-close-circle-outline</v-icon>
            </v-btn>
          </div>
        </div> -->
        <v-toolbar
        :class="'elevation-0'">
          <v-toolbar-title class="body-1 font-weight-bold text-capitalize">
            <!-- {{ selected.event.title }} -->
          </v-toolbar-title>

          <v-spacer/>

          <v-btn icon
            @click="dialog.popup = false; $store.commit('header/config/setPopup', 'after open')"
            color="red">
              <v-icon size="25" class="color-default">mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider
          style="border-top: 2px dashed #e0e0e0;"></v-divider>
        <div v-if="list.popup.length < 1">
          <div class="text-h4 text-center" :style="`color: ${set_color}`">
            Selamat Datang
          </div>
          <v-img
            alt="Pemerintah Kabupaten Klaten"
            :src="require('@/assets/image/welcome.png')">
          </v-img>
        </div>
                <v-card-text v-if="list.popup.length > 0" class="pa-0">
          <v-carousel
            v-model="selected.popup"
            hide-delimiters
            :show-arrows="true"
            :show-arrows-on-hover="true"
            width="100%"
            height="auto">
            <v-carousel-item
              v-for="(item, index) in process.load ? 4 : list.popup"
              :key="index"
              style="height: auto;">
              <v-card
                class="pa-0"
                flat>
                <v-img
                  width="100%"
                  height="100%"
                  contain
                  alt="Kabupaten Klaten"
                  :src="item.image_url"
                  :lazy-src="item.image_url">
                  <!-- <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row> -->
                </v-img>
                <!-- <v-btn
            elevation="0"
            :color="set_color"
            block
            height="50"
                :href="item.link_cta"
                target="_blank"
                @click="dialog.popup = false; $store.commit('header/config/setPopup', 'after open')"
            class="text-capitalize white--text">
            <div class="d-flex align-center justify-center" style="width: 100%;">
              <div class="ml-2 fs-18">
                <span>Lihat Selengkapnya</span>
              </div>
            </div>
          </v-btn> -->
              </v-card>
            </v-carousel-item>
          </v-carousel>
                    <!-- <v-carousel
            v-model="selected.popup"
            hide-delimiters
            :show-arrows="true"
            :show-arrows-on-hover="true"
            width="100%"
            :height="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '60vh' : '40vh'">
            <v-carousel-item
              v-for="(item, index) in process.load ? 4 : list.popup"
              :key="index">
              <v-img
                width="100%"
                v-if="item.mode === 'Image'"
                height="100%"
                :max-height="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '60vh' : '40vh'"
                alt="Kabupaten Klaten"
                :src="item.image_url"
                :lazy-src="item.image_url">
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </v-img>
              <v-card
                width="100%"
                :height="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '60vh' : '40vh'"
                class="rounded-0 elevation-0"
                :style="`background-color: ${set_color}`"
                v-if="item.mode !== 'Image'">
                <v-container style="height: 100%;" class="px-10">

                  <v-row
                    justify="center"
                    style="height: 100%;"
                    align="center">
                    <v-col
                      cols="12"
                      md="5"
                      class="text-center"
                      v-if="(item.mode === 'ImageText' || item.mode === 'ImageTextLink') && $vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'">
                      <img
                        width="375"
                        height="100%"
                        class="mx-auto"
                        alt="Kabupaten Klaten"
                        :src="item.image_url">
                    </v-col>
                    <v-col
                      cols="12"
                      md="7">

                      <div
                        class="white--text mb-4"
                        :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'text-h5' : 'body-2'"
                        style="font-weight: 500;">
                        {{ item.title }}
                      </div>

                      <div
                        class="white--text"
                        :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'subtitle-1' : 'caption'"
                        style="font-weight: 500; opacity: 60%;">
                        {{ item.desc }}
                      </div>

                      <div v-if="item.link_cta !== ''">
                        <v-btn
                          :href="item.link_cta"
                          target="_blank"
                          :width="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '300' : ''"
                          rounded
                          outlined
                          :medium="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? true : false"
                          :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                          color="#FF4949"
                          class="text-subtitle-2 text-capitalize font-weight-bold white--text mt-5">

                          Selengkapnya
                          <v-icon size="20" class="mr-2">mdi-arrow-right</v-icon>
                        </v-btn>
                      </div>

                    </v-col>

                    <v-col
                      cols="12"
                      md="5"
                      class="text-center"
                      v-if="(item.mode === 'TextImage' || item.mode === 'TextImageLink') && $vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'">

                      <img
                        width="375"
                        height="100%"
                        class="mx-auto"
                        alt="Kabupaten Klaten"
                        :src="item.image_url">
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-carousel-item>
          </v-carousel> -->
        </v-card-text>
            </v-card>
        </v-dialog>
    <v-dialog
      v-model="dialog.video"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card class="pa-0 rounded-0" style="background-color: rgb(33, 33, 33); border-color: rgb(33, 33, 33);">
        <v-toolbar
          style="background-color: transparent; box-shadow: none"
        >
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="selected.video = null; dialog.video = false"
            >
              <v-icon size="40">mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <div v-if="selected.video !== null" class="text-center" style="padding-top: calc(100vh - 96vh);">
          <iframe
            :src="`https://www.youtube.com/embed/${selected.video.id.videoId}`"
            frameborder="0" allowfullscreen allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            :style="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? `height: 300px; width: 90%;` : `height: calc(100vh - 200px); width: calc(100vw - 200px);`">
          </iframe>
          <!-- ?autoplay=true -->
        </div>
      </v-card>
    </v-dialog>

    <v-dialog
      persistent
      scrollable
      v-model="dialog.event"
      :min-height="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? '' : 550"
      width="450">
      <v-card v-if="Object.keys(selected.event).length > 0"
        width="450"
        style="border-radius: 15px;"
        flat>
        <v-toolbar
        :class="'elevation-0'">
          <v-toolbar-title class="body-1 font-weight-bold text-capitalize">
            {{ selected.event.title }}
          </v-toolbar-title>

          <v-spacer/>

          <v-btn icon
            @click="dialog.event = false; selected.event = {};"
            color="red">
              <v-icon size="25" class="color-default">mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider
          style="border-top: 2px dashed #e0e0e0;"></v-divider>

        <!-- <v-card-text class="body-1 text-capitalize font-weight-bold pb-0">
          {{ selected.event.title }}
        </v-card-text> -->

        <v-card-text class="body-2 font-weight-light text-first mt-4">
          <p class="mb-2">
            <v-icon small class="mr-1">mdi-calendar-month</v-icon>
            <span v-if="selected.event.start_date === selected.event.end_date">
              Tanggal <b>{{ selected.event.start_date | date }}</b>
            </span>
            <span v-if="selected.event.start_date !== selected.event._date">
              Tanggal <b>{{ selected.event.start_date | date }}</b> s/d <b>{{ selected.event._date | date }}</b>
            </span>
          </p>

          <p class="mb-2">
            <v-icon small class="mr-1">mdi-clock-time-four-outline</v-icon>
            Pukul <b>{{ selected.event.start_time  }}</b> s/d <b>{{ selected.event.end_time }}</b>
          </p>

          <p>
            <v-icon small class="mr-1">mdi-map</v-icon>
            Lokasi <b>{{ selected.event.location !== '' ? selected.event.location : '-' }}</b>
          </p>

          <p>
            <span v-html="selected.event.desc"></span>
          </p>

          <div v-if="selected.event.image_url !== ''" class="mt-4">
            <!-- <v-avatar
              size="100%"
              tile
            > -->
              <v-img :src="selected.event.image_url" contain></v-img>
            <!-- </v-avatar> -->
          </div>
        </v-card-text>
      </v-card>
        </v-dialog>

    <v-layout wrap>
      <v-flex v-if="process.banner" style="width: 100vw;">
        <section>
          <!-- <v-img
            width="100%"
            height="100%"
            min-height="100vh"
            alt="Pemerintah Kabupaten Klaten"
            :src="require('@/assets/background/bg_basic.png')"> -->
            <v-card
              flat
              height="100%"
              :min-height="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '60vh' : '40vh'"
              :max-height="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '60vh' : '40vh'"
              color="#00000040">
              <v-skeleton-loader
                v-if="process.banner"
                :loading="process.banner"
                transition="scale-transition"
                :tile="false"
                class="ma-auto load_card_x_large"
                type="image"
                style="width: 100% !important; height: 100% !important; min-height: 100% !important;">
              </v-skeleton-loader>
            </v-card>
          <!-- </v-img> -->
        </section>
      </v-flex>
      <v-flex v-if="!process.banner && list.banner.length > 0" style="width: 100vw;">

        <section>

          <v-carousel
            :interval="set_duration"
            cycle
            v-model="selected.banner"
            hide-delimiter-background
            :show-arrows="true"
            :show-arrows-on-hover="true"
            width="100%"
            :height="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '100%' : '100%'">
            <v-carousel-item
              v-for="(item, index) in process.banner ? 4 : list.banner"
              :key="index">
              <v-img
                width="100%"
                v-if="item.mode === 'Image'"
                height="100%"
                :max-height="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '100%' : '100%'"
                alt="Pemerintah Kabupaten Klaten"
                :src="item.image_url"
                :lazy-src="item.image_url">
                <!-- <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row> -->
              </v-img>
              <div v-if="item.mode !== 'Image'">
                <v-img
                  width="100%"
                  height="100%"
                  :max-height="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '100%' : '100%'"
                  alt="Pemerintah Kabupaten Klaten"
                  :src="require('@/assets/background/header.jpg')"
                  :lazy-src="require('@/assets/background/header.jpg')">
                  <!-- <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row> -->

                  <v-card
                    width="100%"
                    :height="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '100%' : '100%'"
                    class="rounded-0 elevation-0"
                    :style="`background-color: ${set_color}`"
                    >
                    <v-container style="height: 100%;" class="px-10">
                      <v-row class="fill-height" align="center" justify="center">

                        <v-col
                          cols="12"
                          md="5"
                          class="text-center"
                          style="height: 100%;"
                          v-if="(item.mode === 'ImageText' || item.mode === 'ImageTextLink') && $vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'">
                          <img
                            width="90%"
                            height="90%"
                            class="mx-auto"
                            alt="Pemerintah Kabupaten Klaten"
                            :src="item.image_url">
                            <!-- <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="grey lighten-5"
                              ></v-progress-circular>
                            </v-row> -->
                          <!-- </img> -->
                        </v-col>
                        <v-col
                          cols="12"
                          md="7">

                          <div
                            class="white--text mb-4"
                            :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'text-h5' : 'body-2'"
                            style="font-weight: 500;">
                            {{ item.title }}
                          </div>

                          <div
                            class="white--text"
                            :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'subtitle-1' : 'caption'"
                            style="font-weight: 500; opacity: 60%;">
                            {{ item.desc }}
                          </div>

                          <div v-if="item.link_cta !== ''">
                            <v-btn
                              :href="item.link_cta"
                              target="_blank"
                              :width="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '300' : ''"
                              rounded
                              outlined
                              :medium="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? true : false"
                              :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                              color="#FF4949"
                              class="text-subtitle-2 text-capitalize font-weight-bold white--text mt-5">

                              Selengkapnya
                              <v-icon size="20" class="mr-2">mdi-arrow-right</v-icon>
                            </v-btn>
                          </div>

                        </v-col>

                        <v-col
                          cols="12"
                          md="5"
                          class="text-center"
                          style="height: 100%;"
                          v-if="(item.mode === 'TextImage' || item.mode === 'TextImageLink') && $vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'">
                          <!-- <v-img
                            width="475"
                            height="100%"
                            class="mx-auto"
                            alt="Pemerintah Kabupaten Klaten"
                            :src="item.image_url"
                            :lazy-src="item.image_url">
                          </v-img> -->
                          <img
                            width="90%"
                            height="90%"
                            class="mx-auto"
                            alt="Pemerintah Kabupaten Klaten"
                            :src="item.image_url">
                        </v-col>
                      </v-row>

                    </v-container>
                  </v-card>
                </v-img>
              </div>
            </v-carousel-item>
          </v-carousel>
        </section>
      </v-flex>

      <v-flex style="width: 100vw;">

        <div class="pt-0">
          <v-container id="section-2" class="pb-0">
            <section class="">
              <!-- <div class="text-center" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'text-h6' : 'body-1'">
                Layanan yang sering di akses
              </div> -->
              <v-row>
                <v-col cols="6" md="6" class="pb-0">
                  <div class="font-weight-medium" :style="`color: ${set_color}`" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'text-h5' : 'body-1'">
                    <span :style="`border-bottom: 3px solid ${set_color}`">Layanan</span> Klaten
                  </div>
                </v-col>
                <v-col cols="6" md="6" class="pb-0">
                  <div class="text-right">
                    <v-btn
                      text
                      :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                      :to="'/layanan'"
                      @mouseover="voiceHover('Lihat Semua Layanan')"
                      :style="`color: ${set_color}`"
                      class="text-capitalize button-link-transparent">
                        Lihat Semua
                      <v-icon size="15">mdi-arrow-right</v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>

              <v-card-subtitle :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-1' : 'caption pb-0'" class="px-0 pt-1">
                Layanan - layanan yang terdapat di kabupaten klaten
              </v-card-subtitle>

              <v-sheet
                class="mx-auto mt-9"
                elevation="0"
                v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' "
                style="background-color: transparent;">
                <v-slide-group
                  class="pa-0"
                  prev-icon="mdi-arrow-left-drop-circle-outline"
                  next-icon="mdi-arrow-right-drop-circle-outline"
                  active-class="success"
                  show-arrows>
                  <v-slide-item
                    v-for="(item, index) in process.load ? 4 : list.is_highlight"
                    :key="index">
                    <v-skeleton-loader
                      :loading="process.load"
                      type="image"
                      :class="process.load ? 'px-5' : ''"
                      width="200"
                      tile>
                      <v-hover v-slot="{ hover }">
                        <v-card
                          :href="item.link_url"
                          target="_blank"
                          width="120"
                          @mouseover="voiceHover(item.title)"
                          class="ma-5 rounded-xl box-shadow"
                          :class="hover ? 'on-hover' : 'box-shadow'"
                          :style="hover ? `box-shadow: 0px 5px 15px -10px ${set_color} !important;` : ''"
                        >
                          <v-card-title class="justify-center pb-1 px-1">
                            <v-avatar size="65" tile>
                              <v-img
                                width="65px"
                                contain
                                alt="Kabupaten Klaten"
                                :src="item.icon_url === '' ? require('@/assets/image/no-photo.png') : item.icon_url">
                              </v-img>
                            </v-avatar>
                          </v-card-title>
                          <v-card-text class="text-center py-0 pt-0 pb-2 caption px-1" :class="hover ? 'font-weight-bold' : ''" :style="hover ? `color: ${set_color}` : ''">
                            {{ item.title }}
                          </v-card-text>
                        </v-card>
                      </v-hover>
                    </v-skeleton-loader>
                  </v-slide-item>
                </v-slide-group>
              </v-sheet>

              <v-card-text
              class="pb-0" v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ">
              <v-row>
                <v-col
                  class="px-0">
                  <no-ssr>
                    <swiper
                      v-model="selected.service"
                      ref="swiper"
                      class="swiper"
                      :options="swiperOption">
                      <swiper-slide
                        v-for="(item, index) in process.load ? 10 : list.layanan"
                        :key="index"
                        :class="index < 1 ? '' : index < list.layanan.length - 1 ? '' : ''"
                        style="width: auto !important;">
                        <v-skeleton-loader
                          class="ma-auto"
                          :loading="process.load"
                          type="avatar"
                          tile>
                          <div>
                            <v-card
                              width="80"
                              height="75"
                              :href="item.link_url"
                              @mouseover="voiceHover(item.title)"
                              target="_blank"
                              class="pa-2 box-shadow ma-1"
                              style="border-radius: 10px;">
                              <v-img
                                width="40"
                                height="auto"
                                alt="Pemerintah Kabupaten Klaten"
                                :src="item.icon_url"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>

                              <div
                                class="text-center one-line mt-2"
                                style="font-size: 8px; opacity: 40%;">
                                {{ item.title }}
                              </div>
                            </v-card>
                          </div>
                        </v-skeleton-loader>
                      </swiper-slide>
                    </swiper>
                  </no-ssr>
                </v-col>
              </v-row>
            </v-card-text>


              <!-- <v-row>
                <v-col cols="12" md="2" v-for="(item, index) in process.load ? 4 : list.category"
                  :key="index">
                  <v-hover v-slot="{ hover }">
                    <v-card
                      :to="'/beranda'"
                      class="ma-5 rounded-xl shadow-default"
                      :class="hover ? 'shadow-default-2 on-hover' : 'shadow-default'"
                    >
                      <v-card-title class="justify-center">
                        <v-avatar size="56">
                          <v-img
                            width="100%"
                            height="100%"
                            alt="Kabupaten Klaten"
                            :src="item.icon">
                          </v-img>
                        </v-avatar>
                      </v-card-title>
                      <v-card-text class="text-center py-0 pt-0 pb-2 caption" :class="hover ? 'color-default font-weight-bold' : ''">
                        {{ item.title }}
                      </v-card-text>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row> -->
            </section>
          </v-container>

          <!-- <v-container class="mt-0 pt-0">
            <v-divider></v-divider>
          </v-container> -->


          <!-- <v-container>
            <section class="mt-0">
              <v-row>
                <v-col cols="6" md="6" class="pb-0">
                  <div class="font-weight-medium" :style="`color: ${set_color}`" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'text-h5' : 'body-1'">
                    <span :style="`border-bottom: 3px solid ${set_color}`">Layanan</span> Klaten
                  </div>
                </v-col>
                <v-col cols="6" md="6" class="pb-0">
                  <div class="text-right">
                    <v-btn
                      text
                      :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                      :to="'/layanan'"
                      @mouseover="voiceHover('Lihat Semua Layanan')"
                      :style="`color: ${set_color}`"
                      class="text-capitalize button-link-transparent">
                        Lihat Semua
                      <v-icon size="15">mdi-arrow-right</v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>

              <v-card-subtitle :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-1' : 'caption pb-0'" class="px-0 pt-1">
                Layanan - layanan yang terdapat di kabupaten klaten
              </v-card-subtitle>
            </section>
          </v-container>
          <v-container>
            <v-sheet
              class="mx-auto mt-0"
              elevation="0"
              v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'"
              style="background-color: transparent;">
              <v-slide-group
                class="pa-0"
                prev-icon="mdi-arrow-left-drop-circle-outline"
                next-icon="mdi-arrow-right-drop-circle-outline"
                active-class="success"
                show-arrows>
                <v-slide-item
                  v-for="(item, index) in process.load ? 2 : list.category"
                  :key="index">
                  <v-skeleton-loader
                    :loading="process.load"
                    type="image"
                    :class="process.load ? 'px-5' : ''"
                    width="200"
                    tile>
                    <v-hover v-slot="{ hover }">
                      <v-card
                        @click="selected.category = item; openListLayanan(item.services)"
                        width="250"
                        @mouseover="voiceHover(item.category_title)"
                        class="ma-5 rounded-xl"
                        :class="hover ? 'on-hover' : 'box-shadow'"
                        :style="hover ? `box-shadow: 0px 5px 15px -10px ${set_color} !important;` : ''">
                        <v-card-title class="justify-center pb-1 px-1">
                          <v-avatar size="60" tile>
                            <v-img
                              width="60px"
                              alt="Kabupaten Klaten"
                              :src="item.category_icon_url">
                            </v-img>
                          </v-avatar>
                        </v-card-title>
                        <v-card-text class="text-center py-3 body-1 font-weight-medium" :style="hover ? `color: ${set_color}` : ''" style="min-height: 64px;">
                          {{ item.category_title }}
                        </v-card-text>

                        <v-divider class="mx-4 mb-4"></v-divider>

                        <v-card-text class="text-center pt-0 pb-5 caption">
                          {{ item.category_desc }}
                        </v-card-text>
                      </v-card>
                    </v-hover>
                  </v-skeleton-loader>
                </v-slide-item>
              </v-slide-group>
            </v-sheet>

            <v-card-text
              class="pb-0 pt-0" v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ">
              <v-row>
                <v-col
                  class="px-0 pt-0">
                  <no-ssr>
                    <swiper
                      ref="swiper"
                      class="swiper"
                      :options="swiperOption">
                      <swiper-slide
                        v-for="(item, index) in process.load ? 10 : list.category"
                        :key="index"
                        :class="index < 1 ? 'pl-3 pr-4' : index < list.category.length - 1 ? 'pr-4' : 'pl-4 pr-3'"
                        style="width: auto !important;">
                        <v-skeleton-loader
                          class="ma-auto"
                          :loading="process.load"
                          type="avatar"
                          tile>
                          <div>
                            <v-card
                              width="200"
                              height="100"
                              @click="selected.category = item; openListLayanan(item.services)"
                              @mouseover="voiceHover(item.category_title)"
                              class="px-2 py-5 box-shadow ma-1"
                              style="border-radius: 10px;">
                              <v-img
                                width="40"
                                height="auto"
                                alt="Pemerintah Kabupaten Klaten"
                                :src="item.category_icon_url"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>

                              <div
                                class="text-center one-line mt-2"
                                style="font-size: 14px;">
                                {{ item.category_title }}
                              </div>

                              <div
                                class="text-center one-line mt-2"
                                style="font-size: 10px; opacity: 40%;">
                                {{ item.category_desc }}
                              </div>
                            </v-card>
                          </div>
                        </v-skeleton-loader>
                      </swiper-slide>
                    </swiper>
                  </no-ssr>
                </v-col>
              </v-row>
            </v-card-text>

          </v-container> -->
        </div>
      </v-flex>
      <v-container class="pt-0">
        <v-row>
          <v-col cols="12" md="9">
            <v-container class="pt-0">
              <section class="mb-0">
                <v-row>
                  <v-col cols="6" md="6" class="pb-0">
                    <div class="font-weight-medium" :style="`color: ${set_color}`" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'text-h5' : 'body-1'">
                      <span :style="`border-bottom: 3px solid ${set_color}`">Berita</span>
                    </div>
                  </v-col>
                  <v-col cols="6" md="6" class="pb-0">
                    <div class="text-right">
                      <v-btn
                        text
                        :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                        :to="'/berita'"
                        @mouseover="voiceHover('Lihat Semua Berita')"
                        :style="`color: ${set_color}`"
                        class="text-capitalize button-link-transparent">
                          Lihat Semua
                        <v-icon size="15">mdi-arrow-right</v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>

                <!-- <v-card-subtitle :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-1' : 'caption pb-0'" class="px-0 pt-1">
                  Berita - berita yang terdapat di kabupaten klaten
                </v-card-subtitle> -->

                <div v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'">
                  <v-row>
                    <v-col cols="12" md="4" sm="6" v-for="(item, index) in process.load ? 9 : list.news"
                      :key="index">
                      <v-skeleton-loader
                        :loading="process.load"
                        transition="scale-transition"
                        :tile="false"
                        class="ma-auto"
                        type="card">
                        <div>
                          <div v-if="!process.load && list.news.length > 0"
                            class="border-radius">
                            <v-hover v-slot="{ hover }">
                              <v-card v-if="!process.load && list.news.length > 0"
                                @click="toDetailBerita(item)"
                                @mouseover="voiceHover(item.title)"
                                :class="hover ? 'hover-card-news on-hover' : 'box-shadow'"
                                style="border-radius: 6px; box-shadow: rgba(0, 0, 0, 0.01) 0px 3px 5px;"
                                width="100%">
                                <v-img
                                  width="100%"
                                  height="200"
                                  style="border-radius: 6px; "

                                  alt="Pemerintah Kabupaten Klaten"
                                  :src="item.image_url_cover === '' ? item.image_url === '' ? require('@/assets/image/no-photo.png') : item.image_url : item.image_url_cover"
                                  class="ma-auto">
                                  <!-- <v-card flat
                                    color="transparent"
                                    class="d-flex align-center pa-3"
                                    style="border-radius: 50px;">
                                    <v-avatar size="20">
                                      <v-img
                                        alt="Pemerintah Kabupaten Klaten"
                                        :src="item.post_extra.createdby.image"
                                        class="ma-auto">
                                        <template v-slot:placeholder>
                                          <v-row
                                            class="fill-height"
                                            align="center"
                                            justify="center">
                                            <v-progress-circular
                                              indeterminate
                                              :color="set_color"
                                              size="15">
                                            </v-progress-circular>
                                          </v-row>
                                        </template>
                                      </v-img>
                                    </v-avatar>

                                    <div
                                      class="caption text-capitalize font-weight-bold one-line white--text pl-2">
                                      {{ item.post_extra.createdby.name }}
                                    </div>
                                  </v-card> -->

                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height"
                                      align="center"
                                      justify="center">
                                      <v-progress-circular
                                        indeterminate
                                        :color="set_color">
                                      </v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>

                                <div class="pa-3">
                                  <div class="body-2 font-weight-bold one-line">
                                    {{ item.title }}
                                  </div>

                                  <div class="d-flex align-center mt-1">
                                    <v-icon small color="#212121">mdi-account-circle-outline</v-icon>

                                    <span class="caption one-line ml-1">
                                      <!-- {{ item.autor }} -->Admin
                                    </span>

                                  </div>

                                  <div class="caption font-weight-light three-line my-2" style="opacity: 60%; min-height: 60px; height: 60px; max-height: 60px;">
                                    <span v-html="item.content.replace(/(<([^>]+)>)/ig, '')"></span>
                                  </div>

                                  <!-- <div class="">
                                    <v-chip small class="ma-1 ml-0" style="font-size: 8px;" v-for="(tag, i) in item.tag" :key="i">{{ tag }}</v-chip>
                                  </div> -->

                                  <v-divider class="my-2" style="border: 2px solid #f9f9f9;" />

                                  <div class="caption d-flex align-center" style="opacity: 60%;">
                                    <div>
                                      {{ item.publish_datetime | date }}
                                    </div>

                                    <!-- <v-spacer />

                                    <div>
                                      {{ item.post_create_datetime | time }}
                                    </div> -->
                                  </div>
                                </div>
                              </v-card>
                            </v-hover>
                          </div>
                        </div>
                      </v-skeleton-loader>
                    </v-col>
                  </v-row>
                </div>

                <div v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'">
                  <div class="mt-3">
                    <v-card flat
                      v-for="(item, index) in process.load ? 5 : list.news"
                      :key="index"
                      style="border-radius: 6px;"
                      class="mb-3">
                      <v-skeleton-loader
                        class="ma-auto"
                        :loading="process.load"
                        type="list-item-avatar-three-line">
                        <div>
                          <div v-if="!process.load && list.news.length > 0 && index < 5">
                            <v-list one-line class="" style="border-radius: 6px;">
                              <template>
                                <v-list-item class="" @click="toDetailBerita(item)">
                                  <v-list-item-avatar tile style="width: 100px; height: 70px; border-radius: 6px; margin-top: 1px; margin-bottom: 1px;">
                                    <v-img contain :src="item.image_url_cover === '' ? item.image_url === '' ? require('@/assets/image/no-photo.png') : item.image_url : item.image_url_cover"></v-img>
                                  </v-list-item-avatar>

                                  <v-list-item-content class="py-0">
                                    <div @mouseover="voiceHover(item.title)" style="cursor: pointer" class="fs-14">{{ item.title }}</div>
                                    <!-- <v-list-item-subtitle>
                                      <div>
                                        <v-icon
                                          size="12">
                                          mdi-account-circle-outline
                                        </v-icon>
                                        <span class="me-1 font-small">JDIH Kabupaten Klaten</span>
                                        <span class="ml-2">
                                          <v-icon
                                            size="12">
                                            mdi-calendar-range
                                          </v-icon>
                                          <span class="me-1 font-small"> {{ item.publish_datetime | date }} </span>
                                        </span>
                                        <span class="ml-2">
                                          <v-icon
                                            size="12">
                                            mdi-eye
                                          </v-icon>
                                          <span class="me-1 font-small">Dilihat {{ item.view_count }} Kali </span>
                                        </span>
                                      </div>
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                      <div class="caption font-weight-light three-line my-2" style="opacity: 60%;">
                                        <span v-html="item.content.replace(/(<([^>]+)>)/ig, '')"></span>
                                      </div>
                                    </v-list-item-subtitle> -->
                                  </v-list-item-content>
                                </v-list-item>
                              </template>
                            </v-list>
                          </div>
                        </div>
                      </v-skeleton-loader>
                    </v-card>
                  </div>
                </div>

              </section>
            </v-container>

            <v-container class="pt-0">
              <section class="mb-0">
                <v-row>
                  <v-col cols="6" md="6" class="pb-0">
                    <div class="font-weight-medium" :style="`color: ${set_color}`" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'text-h5' : 'body-1'">
                      <span :style="`border-bottom: 3px solid ${set_color}`">Galeri</span>
                    </div>
                  </v-col>
                  <v-col cols="6" md="6" class="pb-0">
                    <div class="text-right">
                      <v-btn
                        text
                        :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                        :to="'/galeri'"
                        @mouseover="voiceHover('Lihat Semua Galeri')"
                        :style="`color: ${set_color}`"
                        class="text-capitalize button-link-transparent">
                          Lihat Semua
                        <v-icon size="15">mdi-arrow-right</v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>

                <!-- <v-card-subtitle :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-1' : 'caption pb-0'" class="px-0 pt-1">
                  Berita - berita yang terdapat di kabupaten klaten
                </v-card-subtitle> -->

                <div v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'">
                  <!-- <div v-viewer="options" class="images clearfix"> -->
                    <v-row>
                      <v-col cols="6" md="4"
                        v-for="(item, source) in process.load ? 3 : list.galeri"
                        :key="source">

                        <v-skeleton-loader
                          :loading="process.load"
                          transition="scale-transition"
                          :tile="false"
                          class="ma-auto"
                          type="card">
                          <div>
                            <div v-if="!process.load && list.galeri.length > 0"
                              class="border-radius">
                              <v-card
                                :to="`/galeri/${item.id}`"
                                class="box-shadow"
                                style="border-radius: 6px; box-shadow: rgba(0, 0, 0, 0.01) 0px 3px 5px;"
                                @mouseover="voiceHover(item.title)"
                                width="100%">
                                <img
                                  :src="item.galery_url"
                                  class="image"
                                  :data-source="item.galery_url"
                                  :alt="item.desc"
                                  style="width: 100%; height: 200px;"
                                >
                                <!-- <v-img
                                  contain
                                  :height="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'  ? 130 : 250"
                                  alt="Pemerintah Kabupaten Klaten"
                                  :src="item.image_url"
                                  :data-source="item.image_url"
                                  class="ma-auto">

                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height"
                                      align="center"
                                      justify="center">
                                      <v-progress-circular
                                        indeterminate
                                        :color="set_color">
                                      </v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img> -->

                                <div class="pa-3">
                                  <div class="body-2 font-weight-bold three-line" style="min-height: 60px; height: 60px; max-height: 60px;">
                                    {{ item.title }}
                                  </div>
                                </div>
                              </v-card>
                            </div>
                          </div>
                        </v-skeleton-loader>
                      </v-col>
                    </v-row>
                  <!-- </div> -->
                </div>

                <div v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'">
                  <div class="mt-3">
                    <v-card flat
                      v-for="(item, index) in process.load ? 5 : list.galeri"
                      :key="index"
                      style="border-radius: 6px;"
                      class="mb-3">
                      <v-skeleton-loader
                        class="ma-auto"
                        :loading="process.load"
                        type="list-item-avatar-three-line">
                        <div>
                          <div v-if="!process.load && list.galeri.length > 0 && index < 5">
                            <v-list one-line class="" style="border-radius: 6px;">
                              <template>
                                <v-list-item class="" :to="`/galeri/${item.id}`">
                                  <v-list-item-avatar tile style="width: 100px; height: 70px; border-radius: 6px; margin-top: 1px; margin-bottom: 1px;">
                                    <v-img contain :src="item.galery_url"></v-img>
                                  </v-list-item-avatar>

                                  <v-list-item-content class="py-0">
                                    <div @mouseover="voiceHover(item.title)" style="cursor: pointer" class="fs-14">{{ item.title }}</div>
                                    <!-- <v-list-item-subtitle>
                                      <div>
                                        <v-icon
                                          size="12">
                                          mdi-account-circle-outline
                                        </v-icon>
                                        <span class="me-1 font-small">JDIH Kabupaten Klaten</span>
                                        <span class="ml-2">
                                          <v-icon
                                            size="12">
                                            mdi-calendar-range
                                          </v-icon>
                                          <span class="me-1 font-small"> {{ item.publish_datetime | date }} </span>
                                        </span>
                                        <span class="ml-2">
                                          <v-icon
                                            size="12">
                                            mdi-eye
                                          </v-icon>
                                          <span class="me-1 font-small">Dilihat {{ item.view_count }} Kali </span>
                                        </span>
                                      </div>
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                      <div class="caption font-weight-light three-line my-2" style="opacity: 60%;">
                                        <span v-html="item.content.replace(/(<([^>]+)>)/ig, '')"></span>
                                      </div>
                                    </v-list-item-subtitle> -->
                                  </v-list-item-content>
                                </v-list-item>
                              </template>
                            </v-list>
                          </div>
                        </div>
                      </v-skeleton-loader>
                    </v-card>
                  </div>
                </div>

              </section>
            </v-container>

            <v-container class="pt-1">
              <section>
                <v-row>
                  <v-col cols="6" md="6" class="pb-0 pt-0">
                    <div class="font-weight-medium" :style="`color: ${set_color}`" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'text-h5' : 'body-1'">
                      <span :style="`border-bottom: 3px solid ${set_color}`">Video</span>
                    </div>
                  </v-col>
                  <v-col cols="6" md="6" class="pb-0 pt-0">
                    <div class="text-right">
                      <v-btn
                        text
                        :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                        :to="'/video'"
                        @mouseover="voiceHover('Lihat Semua Video')"
                        :style="`color: ${set_color}`"
                        class="text-capitalize button-link-transparent">
                          Lihat Semua
                        <v-icon size="15">mdi-arrow-right</v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>

                <!-- <v-card-subtitle :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'body-1' : 'caption pb-0'" class="px-0 pt-1">
                  Video - video yang terdapat di kabupaten klaten
                </v-card-subtitle> -->
                <!-- <carousel-3d :disable3d="true" :space="500" :clickable="false" :controls-visible="true">
                  <slide v-for="(slide, i) in 7" :index="i">
                    <span class="title">You know</span>
                    <p>You know, being a test pilot isn't always the healthiest business in the world.</p>
                  </slide>
                </carousel-3d> -->
              </section>
            </v-container>
            <v-container v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'">
              <v-row>
                <v-col cols="12" md="4" sm="6" v-for="(item, index) in process.youtube ? 3 : list.video"
                  :key="index">
                  <v-skeleton-loader
                    :loading="process.youtube"
                    transition="scale-transition"
                    :tile="false"
                    class="ma-auto"
                    type="card">
                    <div>
                      <div v-if="!process.youtube && list.video.length > 0"
                        class="border-radius">
                        <v-hover v-slot="{ hover }">
                          <v-card v-if="!process.youtube && list.video.length > 0"
                            @click="openVideo(item)"
                            @mouseover="voiceHover(item.snippet.channelTitle)"
                            :class="hover ? 'hover-card-news on-hover' : 'box-shadow'"
                            style="border-radius: 6px; box-shadow: rgba(0, 0, 0, 0.01) 0px 3px 5px;"
                            width="100%">
                            <v-img
                              width="100%"
                              height="150"
                              style="border-radius: 6px; "

                              alt="Pemerintah Kabupaten Klaten"
                              :src="`https://img.youtube.com/vi/${ item.id.videoId }/hqdefault.jpg`"
                              class="ma-auto white--text align-center cursor-pointer">
                              <div class="d-flex align-center justify-center font-weight-black">
                                <div class="text-center">
                                  <v-icon size="80" color="#FFFFFF">mdi-play-circle</v-icon>
                                  <!-- <div class="white--text text-h5">
                                    Preview Kelas
                                  </div> -->
                                </div>
                              </div>
                              <!-- <v-card flat
                                color="transparent"
                                class="d-flex align-center pa-3"
                                style="border-radius: 50px;">
                                <v-avatar size="20">
                                  <v-img
                                    alt="Pemerintah Kabupaten Klaten"
                                    :src="item.post_extra.createdby.image"
                                    class="ma-auto">
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height"
                                        align="center"
                                        justify="center">
                                        <v-progress-circular
                                          indeterminate
                                          :color="set_color"
                                          size="15">
                                        </v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                </v-avatar>

                                <div
                                  class="caption text-capitalize font-weight-bold one-line white--text pl-2">
                                  {{ item.post_extra.createdby.name }}
                                </div>
                              </v-card> -->
                            </v-img>

                            <div class="pa-3">
                              <div class="body-2 font-weight-bold one-line">
                                <div>{{ item.snippet.channelTitle }}</div>
                              </div>

                              <div class="caption font-weight-light three-line my-2" style="opacity: 60%; min-height: 60px; height: 60px; max-height: 60px;">
                                <span v-html="item.snippet.description"></span>
                              </div>
                            </div>
                          </v-card>
                        </v-hover>
                      </div>
                    </div>
                  </v-skeleton-loader>
                </v-col>
              </v-row>
              <!-- <v-sheet
                class="mx-auto mt-0"
                elevation="0"
                style="background-color: transparent;">
                <v-slide-group
                  class="pa-0"
                  prev-icon="mdi-arrow-left-drop-circle-outline"
                  next-icon="mdi-arrow-right-drop-circle-outline"
                  active-class="success"
                  show-arrows>
                  <v-slide-item
                    v-for="(item, index) in process.youtube ? 3 : list.video"
                    :key="index">
                    <v-skeleton-loader
                      :loading="process.youtube"
                      transition="scale-transition"
                      :tile="false"
                      class="ma-auto"
                      width="400"
                      type="card">
                      <div>
                        <div v-if="!process.youtube && list.video.length > 0"
                          class="border-radius">
                          <v-card
                            class="mx-4 mb-4 mt-0"
                            elevation="0"
                            max-width="400"
                            @mouseover="voiceHover(item.snippet.channelTitle)"
                          >
                            <v-img
                              class="white--text align-center rounded-xl cursor-pointer"
                              height="250"
                              @click="openVideo(item)"
                              :src="`https://img.youtube.com/vi/${ item.id.videoId }/hqdefault.jpg`"
                            >
                              <div class="d-flex align-center justify-center font-weight-black">
                              <div class="text-center">
                                <v-icon size="80" color="#FFFFFF">mdi-play-circle</v-icon>
                              </div>
                            </div>
                            </v-img>

                            <v-card-text class="text--primary pb-0">
                              <div>{{ item.snippet.channelTitle }}</div>
                            </v-card-text>
                            <v-card-subtitle class="" style="min-height: 69px; height: 69px;">
                              <span class="line-text-second" style="white-space: pre-line;" v-html="item.snippet.description"> </span>
                            </v-card-subtitle>
                          </v-card>
                        </div>
                      </div>
                    </v-skeleton-loader>
                  </v-slide-item>
                </v-slide-group>
              </v-sheet> -->
            </v-container>

            <v-container v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'">
              <div>
                <v-card flat
                  v-for="(item, index) in process.youtube ? 5 : list.video"
                  :key="index"
                  style="border-radius: 6px;"
                  class="mb-3">
                  <v-skeleton-loader
                    class="ma-auto"
                    :loading="process.youtube"
                    type="list-item-avatar-three-line">
                    <div>
                      <div v-if="!process.youtube && list.video.length > 0 && index < 5">
                        <v-list one-line class="" style="border-radius: 6px;">
                          <template>
                            <v-list-item class="" @click="openVideo(item)">
                              <v-list-item-avatar tile style="width: 100px; height: 70px; border-radius: 6px; margin-top: 1px; margin-bottom: 1px;">
                                <v-img
                                  alt="Pemerintah Kabupaten Klaten"
                                  :src="`https://img.youtube.com/vi/${ item.id.videoId }/hqdefault.jpg`"
                                  class="ma-auto align-center">
                                  <div class="d-flex align-center justify-center font-weight-black" style="width: 100%;">
                                    <div class="text-center">
                                      <v-icon size="30" color="#FFFFFF">mdi-play-circle</v-icon>
                                      <!-- <div class="white--text text-h5">
                                        Preview Kelas
                                      </div> -->
                                    </div>
                                  </div>

                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height"
                                      align="center"
                                      justify="center">
                                      <v-progress-circular
                                        indeterminate
                                        :color="set_color">
                                      </v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </v-list-item-avatar>

                              <v-list-item-content class="py-0">
                                <div @mouseover="voiceHover(item.snippet.channelTitle)" style="cursor: pointer" class="fs-14">{{ item.snippet.channelTitle }}</div>
                                <!-- <v-list-item-subtitle>
                                  <div>
                                    <v-icon
                                      size="12">
                                      mdi-account-circle-outline
                                    </v-icon>
                                    <span class="me-1 font-small">JDIH Kabupaten Klaten</span>
                                    <span class="ml-2">
                                      <v-icon
                                        size="12">
                                        mdi-calendar-range
                                      </v-icon>
                                      <span class="me-1 font-small"> {{ item.publish_datetime | date }} </span>
                                    </span>
                                    <span class="ml-2">
                                      <v-icon
                                        size="12">
                                        mdi-eye
                                      </v-icon>
                                      <span class="me-1 font-small">Dilihat {{ item.view_count }} Kali </span>
                                    </span>
                                  </div>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle>
                                  <div class="caption font-weight-light three-line my-2" style="opacity: 60%;">
                                    <span v-html="item.content.replace(/(<([^>]+)>)/ig, '')"></span>
                                  </div>
                                </v-list-item-subtitle> -->
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-list>
                      </div>
                    </div>
                  </v-skeleton-loader>
                </v-card>
                <!-- <no-ssr>
                  <swiper
                    class="swiper"
                    ref="swiper"
                    :options="swiperOption">
                    <swiper-slide
                      v-for="(item, index) in process.youtube ? 5 : list.video" :key="index"
                      class="py-3"
                      :class="index < 1 ? 'px-4' : index < list.video.length ? 'pr-4' : 'px-4'"
                      style="width: auto;">
                      <v-skeleton-loader
                        class="ma-auto"
                        width="200"
                        :loading="process.youtube"
                        type="card, article">
                        <div>
                          <v-card v-if="!process.youtube && list.video.length > 0"
                            @click="openVideo(item)"
                            @mouseover="voiceHover(item.snippet.channelTitle)"
                            style="border-radius: 6px; box-shadow: rgba(0, 0, 0, 0.01) 0px 3px 5px;"
                            width="200">
                            <v-img
                              width="100%"
                              height="130"
                              alt="Pemerintah Kabupaten Klaten"
                              :src="`https://img.youtube.com/vi/${ item.id.videoId }/hqdefault.jpg`"
                              class="ma-auto align-center">
                              <div class="d-flex align-center justify-center font-weight-black" style="">
                                <div class="text-center">
                                  <v-icon size="30" color="#FFFFFF">mdi-play-circle</v-icon>
                                </div>
                              </div>

                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular
                                    indeterminate
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>

                            <div class="pa-3">
                              <div class="body-2 font-weight-bold one-line">
                                {{ item.snippet.channelTitle }}
                              </div>

                              <div class="caption font-weight-light two-line my-2" style="opacity: 60%; min-height: 97px; height: 97px;">
                                <span v-html="item.snippet.description.replace(/(<([^>]+)>)/ig, '')"></span>
                              </div>
                            </div>
                          </v-card>
                        </div>
                      </v-skeleton-loader>
                    </swiper-slide>
                  </swiper>
                </no-ssr> -->
              </div>
            </v-container>

            <div style="background: #FFF; width: 100%" class="mt-3 mb-14" v-if="list.video.length < 1 && !process.youtube">
              <v-container>
                <section class="mb-5">
                  <div class="text-center text-h6 mb-4">
                    <v-img
                      class="mx-auto mb-3"
                      contain
                      alt="jdih"
                      :width="100"
                      :height="100"
                      :src="require('@/assets/image/empty.png')">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                    Video Belum Tersedia
                  </div>
                </section>
              </v-container>
            </div>
          </v-col>
          <v-col cols="12" md="3">
            <v-row>
              <v-col cols="12" md="12">
                <!-- <div class="font-weight-medium white--text pa-3 d-flex mb-3" :style="`background-color: ${set_color}`" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'fs-14' : 'fs-12'">
                  <span>Government Public Relations</span>
                </div> -->

                <v-card
                  class="mx-auto button-link-transparent"
                  :class="'box-shadow'">
                  <v-card-text>
                    <div class="text-center black--text">
                      Ayo Ikuti Survei Kepuasan Pelayanan Publik Kabupaten Klaten
                    </div>
                    <v-btn
                      color="primary"
                      class="mt-3 text-capitalize"
                      elevation="0"
                      block
                      target="_blank"
                      href="https://skm.klaten.go.id/"
                      style="border-radius: 6px;">
                      Ikuti Survei
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="12">
                <!-- <v-row v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' " class="mb-6">
                  <v-col cols="6" md="6" class="pb-0 pt-0">
                    <div class="font-weight-medium white--text pa-3 d-flex mb-3" :style="`background-color: ${set_color}`" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'title' : 'body-1'">
                      <span>Pengumuman</span> Klaten
                    </div>
                  </v-col>
                  <v-col cols="6" md="6" class="pb-0 pt-0">
                    <div class="text-right">
                      <v-btn
                        text
                        :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                        :to="'/pengumuman'"
                        :style="`color: ${set_color}`"
                        class="text-capitalize button-link-transparent">
                          Lihat Semua
                        <v-icon size="15">mdi-arrow-right</v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row> -->
                <div class="font-weight-medium white--text pa-3 d-flex mb-3" style="border-radius: 6px;" :style="`background-color: ${set_color}`" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'fs-14' : 'fs-12'">
                  <span class="">Pengumuman</span>
                </div>

                <v-card
                  class="mx-auto button-link-transparent px-2"
                  :class="'box-shadow'">
                  <v-card flat class="mb-2" v-for="(item, index) in list.announcement"
                    :key="index"
                    @click="toDetailPengumuamn(item)">

                    <v-list two-line class="py-0">
                      <template>
                        <v-list-item class="px-2">
                          <v-list-item-avatar tile :size="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 30 : 60">
                            <v-img :src="require('@/assets/icon/loudspeaker.png')"></v-img>
                          </v-list-item-avatar>

                          <v-list-item-content class="fs-13 py-0">
                            <div @click="toDetailPengumuamn(item)" @mouseover="voiceHover(item.title)" style="cursor: pointer">{{ item.title }}</div>
                            <v-list-item-subtitle>
                              <div>
                                <v-icon
                                  size="10">
                                  mdi-account-circle-outline
                                </v-icon>
                                <span class="me-1 fs-10">Admin</span>
                                <span class="ml-1">
                                  <v-icon
                                    size="10">
                                    mdi-calendar-range
                                  </v-icon>
                                  <span class="me-1 fs-10"> {{ item.publish_datetime | date }} </span>
                                </span>
                                <span class="ml-1">
                                  <v-icon
                                    size="10">
                                    mdi-eye
                                  </v-icon>
                                  <span class="me-1 fs-10">{{ item.view_count }} Kali </span>
                                </span>
                              </div>
                            </v-list-item-subtitle>
                            <!-- <v-list-item-subtitle>
                            </v-list-item-subtitle> -->
                            <!-- <div class="text-right mt-5">
                              <v-btn
                                style="border-radius: 6px;"
                                height="40"
                                elevation="0"
                                :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? true : true"
                                color="blue"
                                class="body-2 text-capitalize font-weight-bold white--text">

                                <v-icon size="20" class="mr-2">mdi-text-box-search-outline</v-icon>
                                Lihat Detail
                              </v-btn>
                            </div> -->
                          </v-list-item-content>
                          <!-- <v-list-item-action>
                            <v-chip
                              class="pa-2"
                              text-color="white"
                              x-small
                              color="green"
                              label
                            >
                              Berlaku
                            </v-chip>
                          </v-list-item-action> -->
                        </v-list-item>
                      </template>
                    </v-list>

                    <div class="caption font-weight-light three-line my-2" style="opacity: 60%;">
                      <span v-html="item.content.replace(/(<([^>]+)>)/ig, '')"></span>
                    </div>
                    <v-divider></v-divider>
                  </v-card>
                  <Empty v-if="!process.load && list.announcement.length < 1"
                    class="py-12"
                    margin="my-13"
                    size="125"
                    message="Pengumuman" />

                  <div v-if="!process.load && list.announcement && list.announcement.length > 0">
                    <div class="text-center">
                      <v-btn
                        text
                        :to="'/pengumuman'"
                        @mouseover="voiceHover('Lihat Semua Pengumuman')"
                        :style="`color: ${set_color}`"
                        class="text-capitalize button-link-transparent">
                          Lihat Semua
                        <v-icon size="15">mdi-arrow-right</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" md="12">
                <div class="font-weight-medium white--text pa-3 d-flex mb-3" style="border-radius: 6px;" :style="`background-color: ${set_color}`" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'fs-14' : 'fs-12'">
                  <span class="">Agenda</span>
                </div>
                <v-card
                  class="box-shadow">
                  <v-card-text class="py-0 px-0">
                    <div v-for="(item, index) in list.agenda" :key="index" class="d-flex py-3 align-center" @click="selected.event = item; dialog.event = true">
                      <div class="text-center px-2" style="width: 52px; min-width: 52px; max-width: 52px;">
                        <div class="fs-16">
                          {{ item.start_date | day }}
                        </div>
                        <div>
                          {{ item.start_date | month }}
                        </div>

                      </div>
                      <v-divider
                        class="mr-3 ml-0"
                        vertical
                        style="border-width: medium; border-radius: 15px;"
                        :style="index === 0 ? 'border-color: #00BCD4' : index === 1 ? 'border-color: #009688' : index === 2 ? 'border-color: #CDDC39' : index === 4 ? 'border-color: #03A9F4' : index === 5 ? 'border-color: #03A9F4' : '#CDDC39' "
                      ></v-divider>
                      <div>
                        <div class="fs-13 mb-1">
                          <b>{{ item.title }}</b>
                        </div>
                        <div class="fs-11">
                          <v-icon size="12" class="mr-1">mdi-calendar-month</v-icon>
                          <span v-if="item.start_date === item.end_date">
                            Tanggal <b>{{ item.start_date | date }}</b>
                          </span>
                          <span v-if="item.start_date !== item.end_date">
                            Tanggal <b>{{ item.start_date | date }}</b> s/d <b>{{ item.end_date | date }}</b>
                          </span>
                        </div>
                        <p class="mb-2 fs-11">
                          <v-icon size="12" class="mr-1">mdi-clock-time-four-outline</v-icon>
                          Pukul <b>{{ new Date(`${item.start_date} ${item.start_time}`) | time }}</b> s/d <b>{{ new Date(`${item.end_date} ${item.end_time}`) | time }}</b>
                        </p>
                        <!-- <div>
                          <div class="mt-1">
                            {{ item.title }}
                          </div>
                          <div class="caption font-weight-light two-line mb-2 mt-1" style="opacity: 60%; min-height: 40px; height: 40px; max-height: 40px;">
                            <span v-html="item.desc"></span>
                          </div>
                        </div> -->
                      </div>
                    </div>

                    <div v-if="!process.load && list.agenda && list.agenda.length > 0">
                      <div class="text-center">
                        <v-btn
                          text
                          :to="'/agenda'"
                          @mouseover="voiceHover('Lihat Semua agenda')"
                          :style="`color: ${set_color}`"
                          class="text-capitalize button-link-transparent">
                            Lihat Semua
                          <v-icon size="15">mdi-arrow-right</v-icon>
                        </v-btn>
                      </div>
                    </div>

                    <!-- <v-list three-line class="py-0">
                      <template v-for="(item, index) in list.agenda">

                        <v-list-item :key="index" class="px-0">
                          <v-list-item-content>
                            <div>
                              <v-icon small class="mr-1">mdi-calendar-month</v-icon>
                              <span v-if="item.start_date === item.end_date">
                                Tanggal <b>{{ item.start_date | date }}</b>
                              </span>
                              <span v-if="item.start_date !== item.end_date">
                                Tanggal <b>{{ item.start_date | date }}</b> s/d <b>{{ item.end_date | date }}</b>
                              </span>
                            </div>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                            <v-list-item-subtitle v-html="item.desc"></v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-divider
                          :key="index"
                          v-if="index < (list.agenda.length - 1)"
                        ></v-divider>
                      </template>
                    </v-list> -->
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="12">
                <div class="font-weight-medium white--text pa-3 d-flex mb-3" style="border-radius: 6px;" :style="`background-color: ${set_color}`" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'fs-14' : 'fs-12'">
                  <span class="">Polling</span>
                </div>
                <v-card
                  v-for="(item, index) in list.polling" :key="index"
                  class="box-shadow mb-3">
                  <v-card-text>
                    <div v-if="!item.add_polling">
                      <div v-if="!item.show_hasil">
                        <div class="fs-13 font-weight-bold">
                          {{ item.question }}
                        </div>
                        <v-radio-group dense v-model="item.result_data" class="mt-1">
                          <v-radio
                            :label="item.option_1"
                            value="1"
                            class="polling"
                          ></v-radio>
                          <v-radio
                            :label="item.option_2"
                            value="2"
                            class="polling"
                          ></v-radio>
                          <v-radio
                            :label="item.option_3"
                            value="3"
                            class="polling"
                          ></v-radio>
                          <v-radio
                            :label="item.option_4"
                            value="4"
                            class="polling"
                          ></v-radio>
                          <v-radio
                            :label="item.option_5"
                            value="5"
                            class="polling"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                      <div v-if="item.message !== ''" class="fs-12 red--text py-1">
                        {{ item.message }}
                      </div>
                      <div class="d-flex">
                        <div v-if="!item.add_polling">
                          <v-btn
                            elevation="0"
                            v-if="!item.show_hasil"
                            :color="set_color"
                            @click="toSavePolling(index)"
                            class="text-capitalize white--text mr-2">
                            Vote
                          </v-btn>
                        </div>
                        <v-btn text class="text-capitalize" color="primary" v-if="!item.show_hasil" @click="item.show_hasil = true">
                          Hasil Polling
                        </v-btn>
                      </div>
                      <div v-if="item.show_hasil">
                        <div>
                          <div>
                            {{ item.option_1 }}
                          </div>
                          <v-progress-linear
                            color="light-blue"
                            height="10"
                            :value="item.option_persen_1"
                            striped
                          ></v-progress-linear>
                          <br>
                          <div class="fs-13">
                            {{ item.option_2 }}
                          </div>
                          <v-progress-linear
                            color="light-green darken-4"
                            height="10"
                            :value="item.option_persen_2"
                            striped
                          ></v-progress-linear>
                          <br>
                          <div class="fs-13">
                            {{ item.option_3 }}
                          </div>
                          <v-progress-linear
                            height="10"
                            :value="item.option_persen_3"
                            striped
                            color="lime"
                          ></v-progress-linear>
                          <br>
                          <div class="fs-13">
                            {{ item.option_4 }}
                          </div>
                          <v-progress-linear
                            :value="item.option_persen_4"
                            height="10"
                            striped
                            color="orange"
                          ></v-progress-linear>
                          <br>
                          <div class="fs-13">
                            {{ item.option_5 }}
                          </div>
                          <v-progress-linear
                            :value="item.option_persen_5"
                            height="10"
                            striped
                            color="red"
                          ></v-progress-linear>
                        </div>


                        <v-btn class="text-capitalize mt-2 white--text" elevation="0" color="green" v-if="item.show_hasil" @click="item.show_hasil = false">
                          Isi Polling
                        </v-btn>
                      </div>
                    </div>
                    <div v-if="item.add_polling">
                      <div class="fs-13 font-weight-bold mb-2">
                        {{ item.question }}
                      </div>
                      <div>
                        <div>
                          {{ item.option_1 }}
                        </div>
                        <v-progress-linear
                          color="light-blue"
                          height="10"
                          class="mb-2"
                          :value="item.option_persen_1"
                          striped
                        ></v-progress-linear>
                        <div class="fs-13">
                          {{ item.option_2 }}
                        </div>
                        <v-progress-linear
                          color="light-green darken-4"
                          height="10"
                          class="mb-2"
                          :value="item.option_persen_2"
                          striped
                        ></v-progress-linear>
                        <div class="fs-13">
                          {{ item.option_3 }}
                        </div>
                        <v-progress-linear
                          height="10"
                          class="mb-2"
                          :value="item.option_persen_3"
                          striped
                          color="lime"
                        ></v-progress-linear>
                        <div class="fs-13">
                          {{ item.option_4 }}
                        </div>
                        <v-progress-linear
                          :value="item.option_persen_4"
                          height="10"
                          class="mb-2"
                          striped
                          color="orange"
                        ></v-progress-linear>
                        <div class="fs-13">
                          {{ item.option_5 }}
                        </div>
                        <v-progress-linear
                          :value="item.option_persen_5"
                          height="10"
                          class="mb-2"
                          striped
                          color="red"
                        ></v-progress-linear>
                      </div>
                    </div>

                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" md="12">
                <!-- <div class="font-weight-medium white--text pa-3 d-flex mb-3" :style="`background-color: ${set_color}`" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'fs-14' : 'fs-12'">
                  <span>Government Public Relations</span>
                </div> -->

                <v-card
                  class="mx-auto button-link-transparent"
                  min-height="400"
                  :class="'elevation-0'">
                  <script type="text/javascript" src="https://widget.kominfo.go.id/gpr-widget-kominfo.min.js"></script>
                  <div id="gpr-kominfo-widget-container"></div>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>




      <!-- <v-container>
        <section>
          <v-card>
            <div class="font-weight-medium white--text pa-3 d-flex" :style="`background-color: ${set_color}`" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? 'text-h5' : 'title'">
              <div>
                Survei Kepuasan
              </div>
              <v-spacer></v-spacer>
              <div>
                <v-btn
                  style="border-radius: 6px;"
                  elevation="0"
                  @click="dialog.survey = true"
                  :small="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? false : true"
                  color="white"
                  :style="`color: ${set_color}`"
                  class="text-subtitle-2 text-capitalize font-weight-bold">
                  Isi Survey
                </v-btn>
              </div>
            </div>
            <div class="body-2 mt-4 mb-3 px-5">
              Terima kasih atas penilaian yang telah anda berikan, masukan anda sangat bermanfaat untuk kemajuan unit kami agar terus memperbaiki dan meningkatkan kualitas pelayanan bagi masyarakat
            </div>
            <v-row>
              <v-col cols="12" md="6">
                <div id="chart">
                  <apexchart type="pie" height="350" :options="chartOptionsPie" :series="seriesPie"></apexchart>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </section>
      </v-container> -->

      <!-- <iframe
            :src="`https://widget.diskominfo.go.id/gpr-widget-diskominfo.min.js`"
            frameborder="0" allowfullscreen allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            :style="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? `height: 300px; width: 90%;` : `height: calc(100vh - 200px); width: calc(100vw - 200px);`">
          </iframe> -->

    </v-layout>
  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  import { createSEOMeta } from '@/utils/seo'
  export default {
    // middleware: false,
    data () {
      return {
        seriesPie: [44, 55, 13, 5],
        swiperOption: {
          slidesPerView: 3,
          spaceBetween: 30,
          loop: true,
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          }
        },
        chartOptionsPie: {
          chart: {
            width: 380,
            type: 'pie',
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          labels: ['Sangat Puas', 'Puas', 'Cukup Puas', 'Kurang Puas'],
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }],
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val
              }
            }
          }
        },
        selected: {
          banner: 0,
          popup: 0,
          data: 0,
          video: null,
          layanan: null,
          category: null,
          event: {}
        },
        filter: {
          category: ''
        },
        dialog: {
          video: false,
          layanan: false,
          popup: false,
          review: false,
          success: false,
          event: false,
          survey: false
        },
        options: {
          movable: false,
          zoomable: false,
          url: 'data-source',
        },
        list: {
          polling: [],
          hasil_polling: '',
          banner: [],
          category: [],
          layanan: [],
          internal: [],
          news: [],
          video: [],
          announcement: [],
          agenda: [],
          data: [],
          popup: [],
          galeri: [],
          step: [
            {
              number: '01',
              title: 'Join Member',
              desc: 'Daftar member di Kabupaten Klaten.'
            },
            {
              number: '02',
              title: 'Reservasi Tiket',
              desc: 'Pilih menu Reservasi.'
            },
            {
              number: '03',
              title: 'Pembayaran',
              desc: 'Selesaikan pembayaran tiket.'
            }
          ],
          rating: [],
          is_highlight: []
        },
        form: {
          id: ''
        },
        text: 'Selamat Datang di Website Pemerintah Kabupaten Klaten',
        pagination: {},
        process: {
          load: false,
          category: false,
          form: false,
          youtube: false,
          banner: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/',
          title: 'Beranda',
          description: 'Beranda Kabupaten Klaten',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty'),
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      polling () {
        return this.$store.state.member.auth.polling
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_duration () {
        return this.$store.state.header.config.duration
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      },
      set_color_rgb_down () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.25)`
      }
    },
    mounted () {
      this.fetchDataBanner()
      // this.fetchCategory()
      this.fetchDataListYoutube()
      // this.start()

      // this.fetchYoutube()
    },
    methods: {
      voiceHover (item) {
        responsiveVoice.cancel(); // Cancel anything else that may currently be speaking
        responsiveVoice.speak(item, 'Indonesian Female');
      },
      // speak() {
      //     const msg = new SpeechSynthesisUtterance();
      //     msg.text = this.text;
      //     msg.volume = 1.0; // speech volume (default: 1.0)
      //     msg.pitch = 1.0; // speech pitch (default: 1.0)
      //     msg.rate = 1.0; // speech rate (default: 1.0)
      //     msg.lang = 'id-ID'; // speech language (default: 'en-US')
      //     const voices = window.speechSynthesis.getVoices();
      //     msg.voice = voices[6]; // voice URI (default: platform-dependent)
      //     msg.onboundary = function (event) {
      //         console.log('Speech reached a boundary:', event.name);
      //     };
      //     msg.onpause = function (event) {
      //         console.log('Speech paused:', event.utterance.text.substring(event.charIndex));
      //     };
      //     window.speechSynthesis.speak(msg);
      // },
      async fetchCategory () {
        this.process.category = true
        this.process.load = true

        // let params = {
        //   limit: 10000,

        //   sort: 'DESC',

        //   cat_type: 'produk'
        // }

        await this.$axios.$get(`${process.env.API}sites/home/services_category`)
        .then(response => {
          let res = response

          this.process.category = false

          if (res.status === 200) {
            this.list.category = res.results.data

            // this.filter.category = res.data.length > 0 ? res.data[0].id : ''

            this.fetchDataLayanan()
          }
        })
      },
      async fetchDataBanner () {
        this.process.banner = true
        this.process.load = true

        await this.$axios.$get(`${process.env.API}sites/home/banner`)
        .then(response => {
          let res = response

          // this.process.banner = false

          if (res.status === 200) {
            this.list.banner = res.results.data
            // this.fetchDataLayanan()
            // setTimeout(() => {

              this.process.banner = false
            // }, 2000)
            this.fetchCategory()
          }
        })
      },
      async fetchDataLayanan () {
        this.process.load = true

        // let params = {
        //   limit: 3,

        //   product_cat_id: this.filter.category,

        //   product_status: 'publish'
        // }

        await this.$axios.$get(`${process.env.API}sites/home/services`)
        .then(response => {
          let res = response

          // this.process.load = false
          this.list.is_highlight = []

          if (res.status === 200) {
            this.list.layanan = res.results.data
            for(let i = 0; i < res.results.data.length; i++){
              if(res.results.data[i].is_highlight === '1') {
                this.list.is_highlight.push(res.results.data[i])
              }
            }
            this.fetchDataNews()

            // this.list.data = res.data
            // this.pagination = res.pagination
          }
        })
      },
      async fetchDataNews () {
        this.process.load = true

        let params = {
          // category_id: '1'
          limit: 9,
          sort: '-publish_datetime'
        }

        await this.$axios.$get(`${process.env.API}sites/article/data`, { params })
        .then(response => {
          let res = response

          // this.process.load = false

          if (res.status === 200) {
            this.list.news = res.results.data
            this.fetchDataAnnouncement()

            // this.list.data = res.data
            // this.pagination = res.pagination
          }
        })
      },
      async fetchDataAnnouncement () {
        this.process.load = true

        let params = {
          category_id: '2',
          limit: 2
        }

        await this.$axios.$get(`${process.env.API}sites/article/data`, { params })
        .then(response => {
          let res = response

          // this.process.load = false

          if (res.status === 200) {
            this.list.announcement = res.results.data
            this.fetchDataRating()

            // this.list.data = res.data
            // this.pagination = res.pagination
          }
        })
      },
      async fetchDataListYoutube () {
        this.process.youtube = true

        await this.$axios.$get(`${process.env.API}sites/home/youtube?max_result=3`)
        .then(response => {
          let res = response

          this.process.youtube = false

          if (res.status === 200) {
            // console.log(res.results.data.items)
            this.list.video = res.results.data !== null ? res.results.data.items : []
            // this.fetchDataRating()

            // this.list.data = res.data
            // this.pagination = res.pagination
          }
        })
      },
      async fetchDataRating () {
        this.process.load = true

        await this.$axios.$get(`${process.env.API}sites/survey/data`)
        .then(response => {
          let res = response

          // this.process.load = false

          if (res.status === 200) {
            // console.log(res.results.data.items)
            this.list.rating = res.results.data
            this.seriesPie = []
            this.chartOptionsPie.labels = []
            for(let i = 0; i < res.results.data.length; i++){
              this.seriesPie.push(parseInt(res.results.data[i].total))
              this.chartOptionsPie.labels.push(res.results.data[i].label)
            }
            this.fetchAgenda()

            // this.list.data = res.data
            // this.pagination = res.pagination
          }
        })
      },
      async fetchDataPopup () {
        this.process.load = true

        await this.$axios.$get(`${process.env.API}sites/promo`)
        .then(response => {
          let res = response

          if (res.status === 200) {
            this.list.popup = res.results.data

            if (this.$store.state.header.config.popup === 'to open') {
              if (this.list.popup.length > 0) {
                this.$store.commit('header/config/setPopup', 'open')
              }
            }

            if (this.$store.state.header.config.popup === 'open') {
              this.dialog.popup = true
            }

            this.fetchVoting()

          }
        })
      },
      async fetchVoting () {
        let params = {
          // cookie: '_ga=GA1.1.1085658027.1718705942; _ga_YDWTG8GXR5=GS1.1.1718705941.1.0.1718705948.0.0.0; fcm=e_iB1-KgJM2edmNa47jetw:APA91bGOt_4yHIR1SN86HOd4qac_ajKEsMJRz8S6ObB56jWtRQ-DAH893kfLeNuvSYtNjZLpMs_LHy5v0a66tJzzpdXmQoBS2KRqkXD1tSm6FvPwskvCNO0dKBGExJuzxcQsiinE73zt;'
        }

        await this.$axios.$get(`${process.env.API}sites/voting_poll/data`)
        .then(response => {
          let res = response

          // this.process.banner = false

          if (res.status === 200) {

            this.list.polling = []
            this.list.hasil_polling = []
            res.results.data.map((obj) => {
              this.list.polling.push({
                ...obj,
                result_data: '',
                message: '',
                show_hasil: false,
                add_polling: false
              })
            })
            this.list.hasil_polling = (document.cookie).split(' asw=')[0]
            this.list.polling.map((obj) => {
              obj.cookie.map((item) => {
                if (item === this.list.hasil_polling) {
                  obj.add_polling = true
                }
              })
            })
            // console.log(localStorage.getItem("setPolling"))
            // Cookie.set('polling', JSON.stringify(this.list.hasil_polling))
            // localStorage.setItem("setPolling", JSON.stringify(this.list.hasil_polling));
            // console.log(document.cookie)

            this.fetchGaleri()
          }
        })
      },
      async fetchGaleri () {
        this.process.load = true

        let params = {
          limit: 3,
          sort: '-id'
        }

        await this.$axios.$get(`${process.env.API}sites/galery/data_category`, { params })
        .then(response => {
          let res = response

          // this.process.load = false

          if (res.status === 200) {
            this.list.galeri = res.results.data
            this.process.load = false

            // this.list.data = res.data
            // this.pagination = res.pagination
          }
        })
      },
      async fetchAgenda() {
        // window.scrollTo({
        //   top: 0,
        //   behavior: 'smooth'
        // })

        this.process.load = true

        let params = {
          limit: 3
          // filter: [
          //   {
          //     type: 'string',
          //     field: 'agenda_name',
          //     value: this.filter.title,
          //     comparison: '<>'
          //   },
          //   {
          //     type: 'string',
          //     field: 'agenda_category_name',
          //     value: this.filter.category === 0 ? '' : this.filter.category === 1 ? 'Kelas' : 'Praktek'
          //   }
          // ]
        }

        await this.$axios.$get(`${process.env.API}sites/event/data`, { params })
        .then((response) => {
          let res = response

          if (res.status === 200) {
            this.fetchDataPopup()

            this.list.agenda = res.results.data

            // console.log(data)

            // data.map((obj) => {
            //   this.list.push({
            //     name: obj.title,
            //     desc: obj.desc,
            //     date: obj.start_date,
            //     start: obj.start_date,
            //     end: obj.end_date,
            //     start_time: moment(obj.start_time, "HH:mm:ss").format("hh:mm A"),
            //     end_time: moment(obj.end_time, "HH:mm:ss").format("hh:mm A"),
            //     location: obj.location,
            //     image_url: obj.image_url,
            //     color: this.set_color
            //   })
            // })

          }
        })
      },

      async toSavePolling(index) {
        let tes = "_ga=GA1.1.1085658027.1718705942; _ga_YDWTG8GXR5=GS1.1.1718705941.1.0.1718705948.0.0.0; fcm=e_iB1-KgJM2edmNa47jetw:APA91bGOt_4yHIR1SN86HOd4qac_ajKEsMJRz8S6ObB56jWtRQ-DAH893kfLeNuvSYtNjZLpMs_LHy5v0a66tJzzpdXmQoBS2KRqkXD1tSm6FvPwskvCNO0dKBGExJuzxcQsiinE73zt; asw={'lang':'id'}";
        this.list.polling[index].message = this.list.polling[index].result_data === '' ? 'Harap Isi Jawaban' : ''
        // console.log((document.cookie).split('asw=')[0])

        const isValid = this.list.polling[index].result_data === '' ? false : true
        if (isValid) {
          const isCookie = (document.cookie).split('asw=')[0] === '' ? false : true
          if (!isCookie) {
            this.list.polling[index].message = 'Harap Tidak Menggunakan Incognito'
          } else {
          this.process.form = true
          let form = {
            id: this.list.polling[index].id,
            option: this.list.polling[index].result_data,
            cookie: (document.cookie).split(' asw=')[0]
            // cookie: '_ga=GA1.1.1085658027.1718705942; _ga_YDWTG8GXR5=GS1.1.1718705941.1.0.1718705948.0.0.0; fcm=e_iB1-KgJM2edmNa47jetw:APA91bGOt_4yHIR1SN86HOd4qac_ajKEsMJRz8S6ObB56jWtRQ-DAH893kfLeNuvSYtNjZLpMs_LHy5v0a66tJzzpdXmQoBS2KRqkXD1tSm6FvPwskvCNO0dKBGExJuzxcQsiinE73zt;'
          }

          let url = 'sites/voting_poll/vote'

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API}${url}`, form)
          .then((response) => {

            this.process.form = false

            if (response.status === 200) {
              this.message.success = response.message
              this.dialog.success = true

              this.fetchVoting()
            } else {
              this.message.error = response.message
            }

          })
        }
        }

        // } else {
        //   // AUTO FOCUS ON TEXT-FIELD
        //   setTimeout(() => {
        //     const errors = Object.entries(this.$refs.form.errors)
        //       .map(([key, value]) => ({ key, value }))
        //       .filter(error => error['value'].length)
        //     this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
        //       behavior: 'smooth',
        //       block: 'center'
        //     })
        //   }, 10)
        // }
      },

      toDetailBerita(item) {
        this.$router.push(`/berita/${(item.title.split('-').join('=').split(' ').join('-').split('/').join('>'))}?q=${item.id}`)
      },

      toDetailPengumuamn(item) {
        this.$router.push(`/pengumuman/${(item.title.split('-').join('=').split(' ').join('-').split('/').join('>'))}?q=${item.id}`)
      },

      openListLayanan(item) {
        this.selected.layanan = item
        this.dialog.layanan = true
      },

      openVideo(data) {
        this.selected.video = null
        this.selected.video = data
        this.dialog.video = true
      },

      goToSlidePrev(index) {
        this.selected.data = index === 0 ? 2 : this.selected.data - 1

        this.$refs.carousel3d.goSlide(this.selected.data)
      },
      goToSlide(index) {
        this.selected.data = index

        this.$refs.carousel3d.goSlide(index)
      },
      goToSlideNext(index) {
        this.selected.data = index === 2 ? 0 : this.selected.data + 1

        this.$refs.carousel3d.goSlide(this.selected.data)
      },
      // onAfterSlideChange(index) {
      //   this.selected.data = index

      //   console.log('@onAfterSlideChange Callback Triggered', 'Slide Index ' + index)
      // },
      // onBeforeSlideChange(index) {
      //   console.log('@onBeforeSlideChange Callback Triggered', 'Slide Index ' + index)
      // },
      // onLastSlide(index) {
      //   console.log('@onLastSlide Callback Triggered', 'Slide Index ' + index)
      // }
    }
  }
</script>
