<template>
  <div
    class="fill-height"
    :style="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? `padding-bottom: 280px; padding-top: 128px;` : `padding-bottom: 650px; padding-top: 64px;`">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-container>
            <div>
              <v-card-text
                v-if="process.load"
                class="pb-0 my-4"
                style="height: calc(100vh - 276px); display: flex; align-items: center; justify-content: center;">
                <div class="text-center">
                  <div class="mb-4 title">
                    Mohon Tunggu . . .
                  </div>

                  <v-progress-linear
                    :color="set_color"
                    indeterminate
                    rounded
                    height="10">
                  </v-progress-linear>
                </div>
              </v-card-text>
              <v-card
                flat
                v-if="!process.load"
                color="#fff"
                class="mb-4"
                style="min-height: calc(100vh - 220px);">
                <v-card-text
                  class="pb-0 my-4">
                  <div v-html="content_menu"></div>
                </v-card-text>
              </v-card>
            </div>
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        content_menu: '',
        process: {
          load: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/page/_slug',
          title: `${this.$route.params.slug.split('-').join(' ')} - ${this.$store.state.member.unit.unit.title} Kabupaten Klaten`,
          description: `${this.$route.params.slug.split('-').join(' ')} ${this.$store.state.member.unit.unit.title} Kabupaten Klaten Kabupaten Klaten`,
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {

    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      },
      set_color_rgb_down () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.25)`
      }
    },
    mounted () {
      this.fetchMenuDinamis()
    },
    methods: {
      async fetchMenuDinamis () {
        this.process.load = true

        let params = {
          limit: 1000000,
          url: this.$route.params.slug,
          unit_id: this.$store.state.member.unit.unit.id
        }

        await this.$axios.$get(`${process.env.API}sites/menu_page/data`, { params })
        .then(response => {
          let res = response

          if (res.status === 200) {
            this.content_menu = res.results.data[0].content
            this.process.load = false
          }
        })
      },
    }
  }
</script>
