<template>
  <div
    class="fill-height"
    :style="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? `padding-bottom: 600px; padding-top: 130px;` : `padding-bottom: 900px; padding-top: 64px;`">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-container>
            <v-row>
              <v-col cols="12" md="9" :class="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '' : 'py-0'">
                <div v-if="process.load">
                  <v-card
                    flat
                    height="100%"
                    v-if="process.load"
                    :min-height="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '100vh' : '100vh'"
                    :max-height="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? '100vh' : '100vh'"
                    color="#00000040">
                    <v-skeleton-loader
                      v-if="process.load"
                      :loading="process.load"
                      transition="scale-transition"
                      :tile="false"
                      class="ma-auto load_card_x_large"
                      type="image"
                      style="width: 100% !important; height: 100% !important; min-height: 100% !important;">
                    </v-skeleton-loader>
                  </v-card>
                </div>
              </v-col>
              <v-col cols="12" md="3" v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'">
                <!-- <Sidebar /> -->
                <section>
                  <Sidebar />
                </section>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        dateNow: new Date(),
        detail: {},
        sosmed: [
          { network: 'facebook', icon: require('@/assets/sosmed/ic_share_fb.svg') },
          { network: 'instagram', icon: require('@/assets/sosmed/ic_share_ig.svg') },
          { network: 'twitter', icon: require('@/assets/sosmed/ic_share_tw.svg') },
          { network: 'telegram', icon: require('@/assets/sosmed/ic_share_tel.svg') },
          { network: 'whatsapp', icon: require('@/assets/sosmed/ic_share_wa.svg') }
        ],
        category: [],
        process: {
          load: false,
          category: false
        },
        total_category: 0,
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/berita/:slug',
          title: this.$route.params.slug.split('-').join(' ').split('=').join('-').split('>').join('/'),
          description: 'Detail Berita Kabupaten Klaten',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Sidebar: () => import(/* webpackPrefetch: true */ '@/components/sidebar/index')
    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      },
      unit () {
        return this.$store.state.member.unit.unit
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      },
      set_color_rgb_down () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.25)`
      },
      host () {
        return process.env.HOST_NAME
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      voiceHover (item) {
        responsiveVoice.cancel(); // Cancel anything else that may currently be speaking
        responsiveVoice.speak(item, 'Indonesian Female');
      },
      async fetch () {
        this.process.load = true

        let params = {
          title: (this.$route.params.slug.split('-').join(' ').split('=').join('-').split('>').join('/')),
          unit_id: this.unit.id
        }

        await this.$axios.$get(`${process.env.API}sites/article/data`, { params })
        .then(response => {
          let res = response

          // this.process.load = false

          if (res.status === 200) {
            this.detail = res.results.data[0]
            if (res.results.data.length > 0) {
              this.$router.push({ path: `/berita/${this.$route.params.slug}` })
            } else {
              this.$router.push({ path: '/404' })
            }
            // if (this.detail.doc_type_par_id !== '4') {
            //   this.detail.penetapan_date = new Date(this.detail.penetapan_date).getFullYear()
            // }
          }
        })
      },
      async fetchDetailbyID () {
        this.process.load = true

        let params = {
          id: this.$route.query.q
        }

        await this.$axios.$get(`${process.env.API}sites/article/data`, { params })
        .then(response => {
          let res = response

          // this.process.load = false

          if (res.status === 200) {
            this.detail = res.results.data[0]
            this.fetchDetail()
            if (res.results.data.length < 1 || res.results.data.length > 1) {
              this.process.load = false
            }
            // if (this.detail.doc_type_par_id !== '4') {
            //   this.detail.penetapan_date = new Date(this.detail.penetapan_date).getFullYear()
            // }
          }
        })
      },
      async fetchDetail () {
        this.process.load = true

        let params = {
          id: this.detail.id,
          unit_id: this.unit.id
        }

        await this.$axios.$get(`${process.env.API}sites/article/data`, { params })
        .then(response => {
          let res = response

          this.process.load = false

          if (res.status === 200) {
            this.detail = res.results.data[0]
            this.detail.meta_json = JSON.parse(res.results.data[0].meta_json)
            if (this.detail.image_url_json.length > 0) {
              this.detail.image_url_json = JSON.parse(this.detail.image_url_json)
              this.detail.image_url_json = this.detail.image_url_json.map(item => {
                const container = {};

                container.src = item

                // this.category = container;
                return container
              })
            }
            // this.detail = res.results.data
            // if (this.detail.doc_type_par_id !== '4') {
            //   this.detail.penetapan_date = new Date(this.detail.penetapan_date).getFullYear()
            // }
          }
        })
      },
      // async fetchDetail () {
      //   this.process.load = true

      //   let params = {
      //     id: this.$route.params.slug
      //   }

      //   await this.$axios.$get(`${process.env.API}sites/article/detail`, { params })
      //   .then(response => {
      //     let res = response

      //     this.process.load = false

      //     if (res.status === 200) {
      //       this.detail = res.results.data
      //       this.detail.meta_json = JSON.parse(res.results.data.meta_json)
      //     }
      //   })
      // }
    }
  }
</script>
