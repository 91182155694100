<template>
  <div
    class="fill-height"
    @keydown.esc="selected.tampilan = 'bulan'" tabindex="0"
    :style="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm' ? `padding-bottom: 280px; padding-top: 128px;` : `padding-bottom: 650px; padding-top: 64px;`">

    <v-layout wrap>
      <v-flex>
        <v-card
          v-if="process.run"
          flat
          height="250"
          width="100vw"
          color="#00000040">
          <v-skeleton-loader
            :loading="process.run"
            transition="scale-transition"
            :tile="false"
            class="ma-auto load_card_x_large"
            type="image"
            style="width: 100% !important; height: 100% !important; min-height: 100% !important;">
          </v-skeleton-loader>
        </v-card>
        <section id="fullsc" @keydown.esc="selected.tampilan = 'bulan'" tabindex="0" ref="modal">
          <div v-if="!process.run && selected.tampilan === 'display'" style="height: 100vh;" @keydown.esc="selected.tampilan = 'bulan'" tabindex="0" ref="modal">
            <div>
              <!-- <v-col
                cols="12" class="pt-0"> -->
                <v-card
                  flat
                  cover
                  width="100%" height="100" class="img_center align-center pa-3"
                  :style="`background-color: ${set_color}`">
                  <!-- <v-img :src="require('@/assets/image/Candi-Prambanan.jpg')" width="100%" height="100" style="" :gradient="`to right, ${set_color_rgb_up}, #000000a6`" class="img_center align-center"> -->
                    <!-- <v-card>
                      <v-card-title
                        class="text-h4 white--text justify-center mb-2 pt-6"
                        style="font-weight: 500;">
                        {{ menu[selected.menu].text }}
                      </v-card-title>

                      <v-card-subtitle
                        class="body-1 white--text text-center mb-4"
                        style="opacity: 50%; font-weight: 500;">
                        Mengenal {{ menu[selected.menu].text }} di kabupaten Klaten.
                      </v-card-subtitle>
                    </v-card> -->

                    <div class="d-flex align-center justify-center font-weight-black">
                      <div class="ml-auto" style="width: 300px;">

                      </div>
                      <div class="d-flex justify-center" style="width: 100%;">
                        <div class="mr-3">
                          <v-img
                            contain
                            width="60"
                            height="auto"
                            alt="Kabupaten Klaten"
                            :src="require('@/assets/logo/favicon-logo.png')"
                            class="ma-auto">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular
                                  size="20"
                                  indeterminate
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </div>
                        <div class="text-center">
                          <div
                            class="fs-28 font-weight-bold white--text justify-center mb-2"
                            style="font-weight: 500;">
                            Informasi Agenda kabupaten Klaten
                          </div>

                          <div
                            class="body-1 white--text text-center font-weight-bold"
                            style="opacity: 100%; font-weight: 500;">
                            Mengenal Agenda di kabupaten Klaten.
                          </div>
                        </div>
                      </div>
                      <div class="ml-auto" style="width: 300px;">
                        <div class="white--text text-center">
                          <div v-if="clock.time.length > 0">
                            <div
                              class="fs-18 font-weight-bold"
                              style="opacity: 100%;">
                              {{ clock.date | date }}
                            </div>

                            <div
                              class="fs-25 font-weight-bold">
                              {{ clock.time }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  <!-- </v-img> -->
                </v-card>
              <!-- </v-col> -->
            </div>
            <div style="height: 100%;">
              <v-card flat height="100%">
                <v-card-text>
                  <v-divider class="my-2"></v-divider>
                  <div class="font-weight-bold fs-28 text-center py-3 black--text">
                    AGENDA HARI INI
                  </div>
                  <v-divider class="my-2"></v-divider>
                  <v-card-text
                    v-if="Object.keys(list_now).length < 1"
                    class="pb-0 my-4" style="height: 200px; display: flex; align-items: center; justify-content: center;">
                    <div class="text-center">
                      <v-img
                        class="mx-auto"
                        contain
                        alt="vuetify"
                        :width="90"
                        :height="90"
                        :src="require('@/assets/image/empty.png')">
                        <!-- <template v-slot:placeholder>
                          <v-row
                            class=" "
                            align="center"
                            justify="center">
                            <v-progress-circular
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template> -->
                      </v-img>
                      <div class="mt-3 fs-16">
                        Belum Ada Data
                      </div>
                    </div>
                  </v-card-text>
                  <div v-if="Object.keys(list_now).length > 0">
                    <table style="width: 100%;">
                      <tr>
                        <td class="black--text fs-22 font_weight_500" style="width: 100px; min-width: 100px; max-width: 100px;">

                        </td>
                        <td class="black--text fs-22 font_weight_500" style="padding-left: 16px; width: 216px; min-width: 216px; max-width: 216px;">
                          Jam
                        </td>
                        <td class="black--text fs-22 font_weight_500">
                          Kegiatan
                        </td>
                        <td class="black--text fs-22 font_weight_500" style="width: 300px; min-width: 300px; max-width: 300px;">
                          Tempat
                        </td>
                        <td class="black--text fs-22 font_weight_500" style="width: 250px; min-width: 250px; max-width: 250px;">
                          Hadir
                        </td>
                      </tr>
                    </table>
                    <div style="overflow-y: auto; max-height: calc(100vh - 550px);">

                      <div class="d-flex py-3 align-center" v-for="(item, index) in list_now" :key="index">
                        <!-- {{ item }} -->
                        <div class="text-center px-2 fs-17 font_weight_500 black--text" style="width: 100px; min-width: 100px; max-width: 100px;">
                          <div>
                            {{ new Date() | weekday }}
                          </div>
                          <div class="fs-25">
                            {{ new Date() | day }}
                          </div>
                          <div>
                            {{ new Date() | month }}
                          </div>

                        </div>
                        <v-divider
                          class="mr-3 ml-0"
                          vertical
                          style="border-width: medium; border-radius: 15px;"
                          :style="`border-color: #009688`"
                        ></v-divider>
                        <div style="width: 100%;">
                          <div v-for="(i, index) in item" :key="index"  class="d-flex fs-21 black--text font_weight_500" style="border-top: 1px solid #eee">

                            <div style="width: 200px; min-width: 200px; max-width: 200px;">
                              <p class="my-3 "><span>{{ new Date(`${i.start_date} ${i.start_time}`) | time }}</span> s/d <span>{{ new Date(`${i.end_date} ${i.end_time}`) | time }}</span>
                              </p>
                            </div>
                            <div style="width: 100%" class=" my-3">
                              {{ i.title }}
                            </div>
                            <div style="width: 300px; min-width: 300px; max-width: 300px;" class="my-3">
                              {{ i.location }}
                            </div>
                            <div style="width: 250px; min-width: 250px; max-width: 250px;" class="my-3">
                              {{ i.dihadiri }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <v-divider class="my-2"></v-divider>
                  <div class="font-weight-bold fs-28 text-center py-3 black--text">
                    AGENDA YANG AKAN DATANG
                  </div>
                  <v-divider class="my-2"></v-divider>
                  <table style="width: 100%;">
                    <tr>
                      <td class="black--text fs-22 font_weight_500" style="width: 100px; min-width: 100px; max-width: 100px;">

                      </td>
                      <td class="black--text fs-22 font_weight_500" style="padding-left: 16px; width: 216px; min-width: 216px; max-width: 216px;">
                        Jam
                      </td>
                      <td class="black--text fs-22 font_weight_500">
                        Kegiatan
                      </td>
                      <td class="black--text fs-22 font_weight_500" style="width: 300px; min-width: 300px; max-width: 300px;">
                        Tempat
                      </td>
                      <td class="black--text fs-22 font_weight_500" style="width: 250px; min-width: 250px; max-width: 250px;">
                        Hadir
                      </td>
                    </tr>
                  </table>
                  <v-card-text
                     v-if="Object.keys(list_next).length < 1"
                    class="pb-0 my-4" style="height: 200px; display: flex; align-items: center; justify-content: center;">
                    <div class="text-center">
                      <v-img
                        class="mx-auto"
                        contain
                        alt="vuetify"
                        :width="90"
                        :height="90"
                        :src="require('@/assets/image/empty.png')">
                        <!-- <template v-slot:placeholder>
                          <v-row
                            class=" "
                            align="center"
                            justify="center">
                            <v-progress-circular
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template> -->
                      </v-img>
                      <div class="mt-3 fs-16">
                        Belum Ada Data
                      </div>
                    </div>
                  </v-card-text>
                  <div style="overflow-y: auto; height: calc(100vh - 550px);" v-if="Object.keys(list_next).length > 0">

                    <div v-for="(item, index) in list_next" :key="index" class="d-flex py-3 align-center">
                      <!-- {{ item }} -->
                      <div class="text-center px-2 fs-17 font_weight_500 black--text" style="width: 100px; min-width: 100px; max-width: 100px;">
                        <div class="fs-25">
                          {{ item[0].start_date | day }}
                        </div>
                        <div>
                          {{ item[0].start_date | month }}
                        </div>

                      </div>
                      <v-divider
                        class="mr-3 ml-0"
                        vertical
                        style="border-width: medium; border-radius: 15px;"
                        :style="index === item[0].start_date ? 'border-color: #00BCD4' : index === item[0].start_date ? 'border-color: #009688' : index === item[0].start_date ? 'border-color: #CDDC39' : index === item[0].start_date ? 'border-color: #03A9F4' : index === item[4].start_date ? 'border-color: #03A9F4' : '#CDDC39' "
                      ></v-divider>
                      <div style="width: 100%;">
                        <div v-for="(i, l) in item" :key="l" class="d-flex fs-21 black--text font_weight_500" style="border-top: 1px solid #eee">

                          <div style="width: 200px; min-width: 200px; max-width: 200px;">
                            <p class="my-3 "><span>{{ new Date(`${i.start_date} ${i.start_time}`) | time }}</span> s/d <span>{{ new Date(`${i.end_date} ${i.end_time}`) | time }}</span>
                            </p>
                          </div>
                          <div style="width: 100%;" class=" my-3">
                            {{ i.title }}
                          </div>
                          <div style="width: 300px; min-width: 300px; max-width: 300px;" class="my-3">
                            {{ i.location }}
                          </div>
                          <div style="width: 250px; min-width: 250px; max-width: 250px;" class="my-3">
                            {{ i.dihadiri }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </div>
        </section>
        <div v-if="!process.run">
          <!-- <v-col
            cols="12" class="pt-0"> -->
            <v-card
              flat
              cover
              class="background-default">
              <v-img :src="require('@/assets/image/Candi-Prambanan.jpg')" width="100%" height="150" style="opacity: 0.75;" :gradient="`to right, ${set_color_rgb_up}, #000000a6`" class="img_center align-center">
                <!-- <v-card>
                  <v-card-title
                    class="text-h4 white--text justify-center mb-2 pt-6"
                    style="font-weight: 500;">
                    {{ menu[selected.menu].text }}
                  </v-card-title>

                  <v-card-subtitle
                    class="body-1 white--text text-center mb-4"
                    style="opacity: 50%; font-weight: 500;">
                    Mengenal {{ menu[selected.menu].text }} di kabupaten Klaten.
                  </v-card-subtitle>
                </v-card> -->

                <div class="d-flex align-center justify-center font-weight-black">
                  <div class="text-center">
                    <div
                      class="text-h4 white--text justify-center mb-2 pt-6"
                      style="font-weight: 500;">
                      Agenda
                    </div>

                    <div
                      class="body-1 white--text text-center mb-4"
                      style="opacity: 50%; font-weight: 500;">
                      Mengenal Agenda di kabupaten Klaten.
                    </div>
                  </div>
                </div>

              </v-img>
            </v-card>
          <!-- </v-col> -->
        </div>

        <section>
          <v-container>
            <v-row justify="center">
              <v-col cols="12" class="py-0">
                <v-card flat>
                  <v-card-text class="" >
                    <div class="d-flex justify-end" style="width: 100%;">
                      <v-btn
                        elevation="0"
                        small
                        min-height="30"
                        :color="set_color"
                        to="/display"
                        v-if="!process.run && $vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'"
                        class="fs-12 text-capitalize white--text">
                        Display
                      </v-btn>
                      <div class="ml-3" v-if="selected.tampilan === 'list'">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              elevation="0"
                              outlined
                              dark
                              min-width="30"
                              small
                              min-height="30"
                              color="#7B7B7B"
                              class="text-capitalize px-0"
                              style="border-radius: 6px;"
                              v-bind="attrs"
                              v-on="on"
                              @click="selected.tampilan = 'bulan'">
                              <v-icon size="18">mdi-calendar-month</v-icon>
                            </v-btn>
                          </template>

                          <span>
                            Tampilan Kalender
                          </span>
                        </v-tooltip>
                      </div>

                      <div class="ml-3" v-if="selected.tampilan === 'bulan'">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              elevation="0"
                              outlined
                              dark
                              min-width="30"
                              small
                              min-height="30"
                              color="#7B7B7B"
                              class="text-capitalize px-0"
                              style="border-radius: 6px;"
                              v-bind="attrs"
                              v-on="on"
                              @click="selected.tampilan = 'list'">
                              <v-icon size="18">mdi-format-list-bulleted</v-icon>
                            </v-btn>
                          </template>

                          <span>
                            Tampilan List
                          </span>
                        </v-tooltip>
                      </div>
                    </div>
                  </v-card-text>
                  <v-card-text class="pa-0">
                    <v-toolbar flat color="white" v-if="selected.tampilan === 'bulan'">
                      <v-btn outlined
                        class="mr-4 text-capitalize"
                        color="grey darken-2"
                        @click="date.now = ''">
                        <v-icon small class="mr-1">mdi-calendar-month</v-icon>
                        Hari Ini
                      </v-btn>

                      <v-btn v-if="rps !== 'xs'"
                        fab text
                        color="grey darken-2"
                        @click="$refs.calendar.prev()">
                        <v-icon>mdi-chevron-left</v-icon>
                      </v-btn>

                      <v-btn v-if="rps !== 'xs'"
                        fab text
                        color="grey darken-2"
                        @click="$refs.calendar.next()">
                        <v-icon>mdi-chevron-right</v-icon>
                      </v-btn>

                      <v-toolbar-title v-if="$refs.calendar && rps !== 'xs'">
                        {{ $refs.calendar.title }}
                      </v-toolbar-title>

                      <v-spacer/>

                      <v-menu
                        bottom
                        right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            outlined
                            color="grey darken-2"
                            v-bind="attrs"
                            v-on="on">
                            <span class="text-capitalize">
                              {{ filter.label }}
                            </span>
                            <v-icon right>
                              mdi-menu-down
                            </v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item
                            v-for="(item, index) in menu"
                            :key="index"
                            @click="filter.label = item.label; filter.type = item.value;">
                            <v-list-item-title>
                              {{ item.label }}
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-toolbar>

                    <v-toolbar flat color="white" v-if="selected.tampilan === 'bulan' && rps === 'xs'">
                      <v-btn
                        fab text
                        color="grey darken-2"
                        @click="$refs.calendar.prev()">
                        <v-icon>mdi-chevron-left</v-icon>
                      </v-btn>

                      <v-spacer/>

                      <v-toolbar-title v-if="$refs.calendar">
                        {{ $refs.calendar.title }}
                      </v-toolbar-title>

                      <v-spacer/>

                      <v-btn
                        fab text
                        color="grey darken-2"
                        @click="$refs.calendar.next()">
                        <v-icon>mdi-chevron-right</v-icon>
                      </v-btn>
                    </v-toolbar>
                  </v-card-text>

                  <v-card-text class="py-0" v-if="selected.tampilan === 'bulan'">
                    <v-sheet height="600">
                      <v-calendar
                        ref="calendar"
                        color="#ffcbdc"
                        :type="filter.type"
                        v-model="date.now"
                        :events="list"
                        @click:event="showEvent"
                        @click:more="viewDay"
                        @click:date="viewDay">
                      </v-calendar>

                      <v-menu
                        v-model="selected.open"
                        :close-on-content-click="false"
                        :activator="selected.element"
                        offset-x>
                        <v-card v-if="Object.keys(selected.event).length > 0"
                          width="350"
                          style="border-radius: 15px;"
                          flat>
                          <v-toolbar
                            :color="selected.event.color"
                            dark>
                            <v-toolbar-title class="body-1 font-weight-bold text-capitalize">
                              {{ selected.event.name }}
                            </v-toolbar-title>

                            <v-spacer/>

                            <v-btn icon
                              @click="selected.open = false">
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </v-toolbar>

                          <v-card-text class="body-1 text-capitalize font-weight-bold pb-0">
                            {{ selected.event.name }}
                          </v-card-text>

                          <v-card-text class="body-2 font-weight-light text-first">
                            <p class="mb-2">
                              <v-icon small class="mr-1">mdi-calendar-month</v-icon>
                              <span v-if="selected.event.start === selected.event.end">
                                Tanggal <b>{{ selected.event.date | date }}</b>
                              </span>
                              <span v-if="selected.event.start !== selected.event.end">
                                Tanggal <b>{{ selected.event.start | date }}</b> s/d <b>{{ selected.event.end | date }}</b>
                              </span>
                            </p>

                            <p class="mb-2">
                              <v-icon small class="mr-1">mdi-clock-time-four-outline</v-icon>
                              Pukul <b>{{ selected.event.start_time  }}</b> s/d <b>{{ selected.event.end_time }}</b>
                            </p>

                            <p>
                              <v-icon small class="mr-1">mdi-map</v-icon>
                              Lokasi <b>{{ selected.event.location !== '' ? selected.event.location : '-' }}</b>
                            </p>

                            <p>
                              <span v-html="selected.event.desc"></span>
                            </p>

                            <div v-if="selected.event.image_url !== ''" class="mt-4">
                              <!-- <v-avatar
                                size="100%"
                                tile
                              > -->
                                <v-img :src="selected.event.image_url" contain></v-img>
                              <!-- </v-avatar> -->
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </v-sheet>
                  </v-card-text>
                  <v-card-text class="py-0" v-if="selected.tampilan === 'list'" style="min-height: 600px">
                    <div>
                      <v-card flat>
                        <v-card-text v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'">
                          <table style="width: 100%;">
                            <tr>
                              <td class="black--text fs-16 font_weight_500" style="width: 100px; min-width: 100px; max-width: 100px;">

                              </td>
                              <td class="black--text fs-16 font_weight_500" style="padding-left: 16px; width: 216px; min-width: 216px; max-width: 216px;">
                                Jam
                              </td>
                              <td class="black--text fs-16 font_weight_500">
                                Kegiatan
                              </td>
                              <td class="black--text fs-16 font_weight_500" style="width: 300px; min-width: 300px; max-width: 300px;">
                                Tempat
                              </td>
                              <td class="black--text fs-16 font_weight_500" style="width: 250px; min-width: 250px; max-width: 250px;">
                                Hadir
                              </td>
                            </tr>
                          </table>
                          <v-card-text
                            v-if="Object.keys(list_agenda).length < 1"
                            class="pb-0 my-4" style="height: 200px; display: flex; align-items: center; justify-content: center;">
                            <div class="text-center">
                              <v-img
                                class="mx-auto"
                                contain
                                alt="vuetify"
                                :width="90"
                                :height="90"
                                :src="require('@/assets/image/empty.png')">
                                <!-- <template v-slot:placeholder>
                                  <v-row
                                    class=" "
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template> -->
                              </v-img>
                              <div class="mt-3 fs-16">
                                Belum Ada Data
                              </div>
                            </div>
                          </v-card-text>
                          <div v-if="Object.keys(list_agenda).length > 0">

                            <div v-for="(item, index) in list_agenda" :key="index" class="d-flex py-2 align-center">
                              <!-- {{ item }} -->
                              <div class="text-center px-2 fs-16 font_weight_500 black--text" style="width: 100px; min-width: 100px; max-width: 100px;">
                                <div class="fs-20">
                                  {{ item[0].start_date | day }}
                                </div>
                                <div>
                                  {{ item[0].start_date | month }}
                                </div>

                              </div>
                              <v-divider
                                class="mr-3 ml-0"
                                vertical
                                style="border-width: medium; border-radius: 15px;"
                                :style="index === item[0].start_date ? 'border-color: #00BCD4' : index === item[0].start_date ? 'border-color: #009688' : index === item[0].start_date ? 'border-color: #CDDC39' : index === item[0].start_date ? 'border-color: #03A9F4' : index === item[4].start_date ? 'border-color: #03A9F4' : '#CDDC39' "
                              ></v-divider>
                              <div style="width: 100%;">
                                <div v-for="(i, l) in item" :key="l" class="d-flex fs-16 black--text py-1 font_weight_500" style="border-top: 1px solid #eee">

                                  <div style="width: 200px; min-width: 200px; max-width: 200px;">
                                    <p class="my-3 "><span>{{ new Date(`${i.start_date} ${i.start_time}`) | time }}</span> s/d <span>{{ new Date(`${i.end_date} ${i.end_time}`) | time }}</span>
                                    </p>
                                  </div>
                                  <div style="width: 100%;" class=" my-3">
                                    {{ i.title }}
                                  </div>
                                  <div style="width: 300px; min-width: 300px; max-width: 300px;" class="my-3">
                                    {{ i.location }}
                                  </div>
                                  <div style="width: 250px; min-width: 250px; max-width: 250px;" class="my-3">
                                    {{ i.dihadiri === '' ? '-' : i.dihadiri }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </v-card-text>

                        <div v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'">
                          <v-card-text
                            v-if="Object.keys(list_agenda).length < 1"
                            class="pb-0 my-4" style="height: 200px; display: flex; align-items: center; justify-content: center;">
                            <div class="text-center">
                              <v-img
                                class="mx-auto"
                                contain
                                alt="vuetify"
                                :width="90"
                                :height="90"
                                :src="require('@/assets/image/empty.png')">
                                <!-- <template v-slot:placeholder>
                                  <v-row
                                    class=" "
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template> -->
                              </v-img>
                              <div class="mt-3 fs-16">
                                Belum Ada Data
                              </div>
                            </div>
                          </v-card-text>
                          <v-card-text class="py-0 px-0">
                            <div v-for="(item, index) in list_agenda" :key="index" class="d-flex py-3 align-center" >
                              <div class="text-center px-2" style="width: 52px; min-width: 52px; max-width: 52px;">
                                <div class="fs-16">
                                  {{ item[0].start_date | day }}
                                </div>
                                <div>
                                  {{ item[0].start_date | month }}
                                </div>

                              </div>
                              <v-divider
                                class="mr-3 ml-0"
                                vertical
                                style="border-width: medium; border-radius: 15px;"
                                :style="index === item[0].start_date ? 'border-color: #00BCD4' : index === item[0].start_date ? 'border-color: #009688' : index === item[0].start_date ? 'border-color: #CDDC39' : index === item[0].start_date ? 'border-color: #03A9F4' : index === item[4].start_date ? 'border-color: #03A9F4' : '#CDDC39' "
                              ></v-divider>
                              <div>
                                <div v-for="(i, l) in item" :key="l">
                                  <div class="fs-13 mb-1">
                                    <b>{{ i.title }}</b>
                                  </div>
                                  <!-- <div class="fs-11">
                                    <v-icon size="12" class="mr-1">mdi-calendar-month</v-icon>
                                    <span v-if="item.start_date === item.end_date">
                                      Tanggal <b>{{ item.start_date | date }}</b>
                                    </span>
                                    <span v-if="item.start_date !== item.end_date">
                                      Tanggal <b>{{ item.start_date | date }}</b> s/d <b>{{ item.end_date | date }}</b>
                                    </span>
                                  </div> -->
                                  <p class="mb-1 fs-11">
                                    <v-icon size="12" class="mr-1">mdi-map</v-icon>
                                    {{ i.location }}
                                  </p>
                                  <p class="mb-0 fs-11">
                                    <v-icon size="12" class="mr-1">mdi-clock-time-four-outline</v-icon>
                                    Pukul <b>{{ new Date(`${i.start_date} ${i.start_time}`) | time }}</b> s/d <b>{{ new Date(`${i.end_date} ${i.end_time}`) | time }}</b>
                                  </p>
                                  <v-divider
                                    v-if="l < (item.length - 1)"
                                    class="my-1"
                                  ></v-divider>
                                  <!-- <div>
                                    <div class="mt-1">
                                      {{ item.title }}
                                    </div>
                                    <div class="caption font-weight-light two-line mb-2 mt-1" style="opacity: 60%; min-height: 40px; height: 40px; max-height: 40px;">
                                      <span v-html="item.desc"></span>
                                    </div>
                                  </div> -->
                                </div>
                              </div>
                            </div>

                            <!-- <v-list three-line class="py-0">
                              <template v-for="(item, index) in list.agenda">

                                <v-list-item :key="index" class="px-0">
                                  <v-list-item-content>
                                    <div>
                                      <v-icon small class="mr-1">mdi-calendar-month</v-icon>
                                      <span v-if="item.start_date === item.end_date">
                                        Tanggal <b>{{ item.start_date | date }}</b>
                                      </span>
                                      <span v-if="item.start_date !== item.end_date">
                                        Tanggal <b>{{ item.start_date | date }}</b> s/d <b>{{ item.end_date | date }}</b>
                                      </span>
                                    </div>
                                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                                    <v-list-item-subtitle v-html="item.desc"></v-list-item-subtitle>
                                  </v-list-item-content>
                                </v-list-item>

                                <v-divider
                                  :key="index"
                                  v-if="index < (list.agenda.length - 1)"
                                ></v-divider>
                              </template>
                            </v-list> -->
                          </v-card-text>
                        </div>
                      </v-card>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import moment from 'moment';
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        clock: {
          date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
          time: ''
        },
        dialog: false,
        menu: [
          {
            label: 'Bulan',
            value: 'month'
          },
          {
            label: 'Minggu',
            value: 'week'
          },
          {
            label: 'Hari',
            value: 'day'
          },
          {
            label: '4 Hari',
            value: '4day'
          }
        ],
        category: ['Semua', 'Kelas', 'Praktek'],
        filter: {
          title: '',
          category: 0,
          calendar: true,
          label: 'Bulan',
          type: 'month'
        },
        picker: {
          date: false
        },
        date: {
          min: moment().format('Y-MM-DD'),
          now: moment().format('Y-MM-DD')
        },
        selected: {
          open: false,
          element: null,
          event: {},
          tampilan: 'bulan'
        },
        colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
        list: [],
        list_now: [],
        list_next: [],
        list_agenda: {},
        pagination: {},
        process: {
          run: false
        },
        // SEO
        content: {
          url: '/agenda',
          title: `Agenda - ${this.$store.state.member.unit.unit.title} Kabupaten Klaten`,
            description: `Agenda ${this.$store.state.member.unit.unit.title} Kabupaten Klaten`,
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Pagination: () => import('@/components/Pagination')
    },
    watch: {
      'filter.calendar': function() {
        this.filter.title = ''
        this.filter.category = 0

        this.fetch()
      },
      'filter.category': function() {
        this.fetch()
      },
      document: {
        keyup: function (e) {
          if (e.keyCode === 27) {
            this.selected.tampilan = 'bulan'
          }
        }
      },
      'Document.fullscreenElement': function () {
        console.log(Document.fullscreenElement)
        if (Document.fullscreenElement) {
          this.$refs.modal.focus()
        } else {
          this.selected.tampilan = 'bulan'
        }
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        console.log(this.$store.state.header.config.color)
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      },
      set_color_rgb_down () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.25)`
      }
    },
    created() {
        let that = this;

        document.addEventListener('keyup', function (evt) {
          console.log(evt.keyCode)
            if (evt.keyCode === 27) {
                that.selected.tampilan = 'bulan';
                console.log(that.selected.tampilan)
            }
        });
    },

    mounted () {

      setInterval(this.updateTime, 1000)
      this.fetch()
    },
    methods: {
      updateTime () {
        let cd = new Date()

        this.clock.time = this.zeroPadding(cd.getHours(), 2) + ':' + this.zeroPadding(cd.getMinutes(), 2) + ':' + this.zeroPadding(cd.getSeconds(), 2)
      },
      zeroPadding (num, digit) {
        let zero = ''

        for(let i = 0; i < digit; i++) {
          zero += '0'
        }
        return (zero + num).slice(-digit)
      },
      async launchIntoFullscreen() {
        let elem = document.getElementById("fullsc")
        if(elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if(elem.mozRequestFullScreen) {
          elem.mozRequestFullScreen();
        } else if(elem.webkitRequestFullscreen) {
          elem.webkitRequestFullscreen();
        } else if(elem.msRequestFullscreen) {
          elem.msRequestFullscreen();
        }
        this.$refs.modal.focus();
      },
      async fetch(pagination = { row: 20, page: 1 }) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })

        this.process.run = true

        let params = {
          limit: 10000000,
          unit_id: this.$store.state.member.unit.unit.id
          // filter: [
          //   {
          //     type: 'string',
          //     field: 'agenda_name',
          //     value: this.filter.title,
          //     comparison: '<>'
          //   },
          //   {
          //     type: 'string',
          //     field: 'agenda_category_name',
          //     value: this.filter.category === 0 ? '' : this.filter.category === 1 ? 'Kelas' : 'Praktek'
          //   }
          // ]
        }

        this.list = []

        await this.$axios.$get(`${process.env.API}sites/event/data`, { params })
        .then((response) => {
          let res = response

          if (res.status === 200) {
            this.process.run = false

            let data = res.results.data
            this.list_now = []
            this.list_next = []

            let date_now = res.results.data.filter(({ start_date }) => start_date === new Date().toISOString().split('T')[0])
            let date_next = res.results.data.filter(({ start_date }) => start_date > new Date().toISOString().split('T')[0])
            const result = Object.groupBy(data, ({ start_date }) => start_date);
            this.list_now = Object.groupBy(date_now, ({ start_date }) => start_date);
            this.list_next = Object.groupBy(date_next, ({ start_date }) => start_date)
            this.list_agenda = (data).length > 0 ? result : {}

            console.log(data)

            data.map((obj) => {
              this.list.push({
                name: obj.title,
                desc: obj.desc,
                date: obj.start_date,
                start: obj.start_date,
                end: obj.end_date,
                start_time: moment(obj.start_time, "HH:mm:ss").format("hh:mm A"),
                end_time: moment(obj.end_time, "HH:mm:ss").format("hh:mm A"),
                location: obj.location,
                image_url: obj.image_url,
                color: this.set_color,
                dihadiri: obj.dihadiri
              })
            })

          }
        })
      },
      viewDay ({ date }) {
        this.focus = date
        this.filter.type = 'day'
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
      showEvent ({ nativeEvent, event }) {
        const open = () => {
          this.selected.event = event
          this.selected.element = nativeEvent.target
          setTimeout(() => {
            this.selected.open = true
          }, 10)
        }

        if (this.selected.open) {
          this.selected.open = false
          setTimeout(open, 10)
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      }
    }
  }
</script>

<style scoped>
.search-style.v-text-field--outlined >>> fieldset {
  border-color: #dadada;
}
</style>

<style>
table, th, td {
    /* border: 1px solid #ededed;
    border-collapse: collapse; */
    /* border: thin ridge black; */
    border-spacing: 4px;
    border-collapse: collapse;
  }
</style>
